import { useEffect, useState } from "react";
import "../../style/admin/live-games.css";
import { useNavigate } from "react-router-dom";
import gameService from "../../services/gameService";
// import authService from '../../services/authService';
import AdminHeader from "../../components/AdminHeader";

function AdminLiveGames() {
  let navigate = useNavigate();
  // const [user, setUser] = useState(null);
  const gameDeatils = (id,tokenAddress) => {
    navigate(`/admin/live-games/${id}`,{
      state: {
        tokenAddress:tokenAddress,
      }
    });
  };
  const newGame = () => {
    navigate(`/admin/new-game`);
  };

  const [ liveGames, setLiveGames] = useState([])
  const [ games, setGames] = useState([])
  const [past, setPast] = useState();

  useEffect(()=>{
    const fetchGame = async () => {
      const {data} = await gameService.listAdmin()
      setGames(data.result);
      setPast(false);
    }
    fetchGame()
  },[])
  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const { data } = await authService.me();
  //     if (data) {
  //       setUser(data)
  //   }
  // }
  //   fetchUser()

  // }, [])

  useEffect(()=>{
    const arrGame = [...games];
    if(past){
      setLiveGames(arrGame.filter(game => game.status === false))
    }else{
      setLiveGames(arrGame.filter(game => game.status === true))
    }
  },[games, past])

  return (
    <>
      {/* <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          PASS<span className="yellow-text">PHRASE</span>
        </div>
        <div className="navbar-user">
          <i className="fas fa-user-circle user-avatar mr-5"></i>
          <span>{user?.name}</span>
        </div>
      </div> */}
      <AdminHeader/>

      <div className="admin-live-nav-text">
        <span className="me-4 hover" onClick={()=>{setPast(false)}}>Live games</span>
        <span className="hover" onClick={()=>{setPast(true)}}>Past games</span>
      </div>
      <div className="admin-live-nav-bar">
        {!past? <div className="admin-bar-active"></div> : <div className="admin-bar-active-past"></div>}


      </div>

      <div className="game-container d-flex">
     
        {liveGames.length === 0 ? <div className="admin-no-game-text">
        There are currently no games available
          </div> : liveGames.map((val,index) => (
          <div
            key={index}
            id={val.id}
            onClick={() => {
              gameDeatils(val._id,val.tokenAddress);
            }}
            className="game-card m-2"
          >
            <div className="game-card-title">
              {val.numberPlayer} <br />
              players
            </div>
            <table className="mt-65">
                <tbody>
              <tr>
                <td>
                  <img src="/game-1.png" alt=""/>
                </td>
                <td className="game-table-text">{val.sold} NFTs sold</td>
              </tr>
              <tr>
                <td>
                  <img src="/game-2.png" alt=""/>
                </td>
                <td className="game-table-text ps-1">Started {val.time} ago</td>
              </tr>
              <tr>
                <td>
                  <img src="/game-3.png" alt=""/>
                </td>
                <td className="game-table-text ps-1">{val.link}</td>
              </tr>
          
            </tbody>
            </table>
          </div>
        ))}
      </div>
      <div onClick={newGame} className="new-game-button mb-5">
        Start Another Game
      </div>
     
    </>
  );
}

export default AdminLiveGames;
