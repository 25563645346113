import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import debounce from 'lodash.debounce';

import backArrow from '../assets/icons/backarrow.svg';
import Footer from '../components/Footer';
import Header from '../components/Header';
import NftCollectionCard from '../components/nftCollectionCard';
import gameService from '../services/gameService';
import playerService from '../services/playerService';
import '../style/nft-collection.css';
import ReactPaginate from 'react-paginate';


const NftCollection = (props) => {
    const { balance, setAccount, setBalance, account } = props;
    const params = useParams();
    const [nfts, setNfts] = useState([]);
    const [nftsFilter, setnftsFilter] = useState([]);
    const [game, setGame] = useState();
    const [filter, setFilter] = useState(0);
    const filterText = ['View all', 'Sold', 'UnSold'];
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const nftPerPage = 40;
    const pagesVisited = pageNumber * nftPerPage;
    const pageCount = Math.ceil(nfts.length / nftPerPage);
    const debouncedResults = useMemo(() => {
        return debounce(searchWord, 500);
    }, []);
    const changePage = ({ selected }) => {
        setLoading(true);
        setPageNumber(selected);
        setLoading(false);
      };
    async function fetchGame() {
        setLoading(true);
        const { data } = await gameService.view(params.id);
        setGame(data?.result);
        const { data: nftList } = await playerService.list({ game: params.id ,all:true});
        setNfts(nftList?.result);
        setnftsFilter(nftList?.result);
        setLoading(false);
    }

    async function searchWord(e) {
        const { data: nftList } = await playerService.list({
            game: params.id,
            word: e.target.value.toUpperCase(),
        });
        setNfts(nftList?.result);
    }
    async function filterNfts(numberFilter) {
        setFilter(numberFilter)
        let arrFilter = []
        let arrAll = [...nftsFilter]
        if (numberFilter === 1) {
            arrFilter = arrAll?.filter(p => p.isBought  === true);
            setNfts(arrFilter);
          }
        if (numberFilter === 2) {
            arrFilter = arrAll?.filter(p => p.isBought  !== true);
            setNfts(arrFilter);
        }  
        if (numberFilter === 0)  {
            setNfts(arrAll);
        }
    }
    

    useEffect(() => {
        fetchGame();
    }, [params.id]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <>
            <div>
                <Header
                    balance={balance}
                    account={account}
                    setAccount={setAccount}
                    setBalance={setBalance}
                    backArrow={backArrow}
                />
                <div className="banner-section">
                    <div className="banner-container">
                        <h1>
                            {Math.round(game?.price * game?.numberPlayer * 0.8 * 10000) / 10000} ETH{' '}
                            <span className="yellow-text">WINNING PRIZE</span>
                        </h1>
                    </div>
                </div>
                <div className="dropdown dropdown-row">
                    <div className="dropdown-items">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Filter by: {filterText[filter]}
                        </button>

                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a className="dropdown-item"  onClick={()=>filterNfts(0)}>
                                    View all
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" onClick={()=>filterNfts(1)}>
                                    Sold
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item"  onClick={()=>filterNfts(2)}>
                                    UnSold
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <form className="form-inline my-2 my-lg-0">
                            <input
                                className="form-control mr-sm-2 h-100"
                                onChange={debouncedResults}
                                type="search"
                                placeholder="Search..."
                                aria-label="Search"
                            />
                        </form>
                    </div>

                    {isOwner && (
                        <Link className="navbar-login cursor-pointer hover" to={`/app/instructions/${game._id}`}>
                            Play Game
                        </Link>
                    )}
                </div>
                {loading ? <div className="mt-131 sold-out-text">Loading<div className="stage"><div className="dot-pulse"></div></div></div> : 
                
                
                <div className="nft-card-wrapper">
                    
                    <div className="nft-card-row">
                        {nfts.length > 0 &&
                            [...nfts]
                            .slice(pagesVisited, pagesVisited + nftPerPage).map((info, key) => <NftCollectionCard info={info} key={key} game={game} />)}
                    </div>
                    <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    />
                </div>
                        
                }
                <div className="row mt-192">
                    <div className="col">
                        <span className="footer-text">
                            Pass<span className="yellow-text">Phrase</span>
                        </span>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default NftCollection;
