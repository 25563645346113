import React, { useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
function Tab({ gameId , loading }) {
    let navigate = useNavigate();
    const location = useLocation();
    const id = gameId || '000000';
    useEffect(() => {
        if(!gameId && !location.pathname.includes('app/game')) {
            toast.error('Network error', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
    },[])

    if (location.pathname.includes('app/game')) {
        return (
            <Nav className='d-flex'>
                <div className='d-flex align-items-center justify-content-center game-opt my-1 cursor-pointer' onClick={() => 
                            {
                                if(!loading) {
                                    navigate(`/app/nft-collection-detail-player/${id}`)
                                }
                            }
                    }>
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6663 11.5002V0.833496H2.99967V11.5002H13.6663ZM6.33301 7.50016L7.68634 9.30683L9.66634 6.8335L12.333 10.1668H4.33301L6.33301 7.50016ZM0.333008 3.50016V14.1668H10.9997V12.8335H1.66634V3.50016H0.333008Z" fill="#BA9C00" />
                    </svg>
                    <div className="ms-2">
                        <span className="yellow-text hover">Collections</span>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center game-opt active mx-3 my-1' onClick={() =>
                        {
                            if(!loading) {
                                navigate(`/app/game`)
                            }
                        }
                          }>
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33366 6.83317H14.667V8.1665H1.33366V6.83317ZM2.10033 4.13317L2.66699 3.1465L3.23366 4.13317L4.10033 3.63317L3.53366 2.6465H4.66699V1.6465H3.53366L4.10033 0.666504L3.23366 0.166504L2.66699 1.1465L2.10033 0.166504L1.23366 0.666504L1.80033 1.6465H0.666992V2.6465H1.80033L1.23366 3.63317L2.10033 4.13317ZM6.56699 3.63317L7.43366 4.13317L8.00033 3.1465L8.56699 4.13317L9.43366 3.63317L8.86699 2.6465H10.0003V1.6465H8.86699L9.43366 0.666504L8.56699 0.166504L8.00033 1.1465L7.43366 0.166504L6.56699 0.666504L7.13366 1.6465H6.00033V2.6465H7.13366L6.56699 3.63317ZM15.3337 1.6465H14.2003L14.767 0.666504L13.9003 0.166504L13.3337 1.1465L12.767 0.166504L11.9003 0.666504L12.467 1.6465H11.3337V2.6465H12.467L11.9003 3.63317L12.767 4.13317L13.3337 3.1465L13.9003 4.13317L14.767 3.63317L14.2003 2.6465H15.3337V1.6465Z" fill="black" />
                    </svg>
                    <div className="ms-2 hover">
                        <span>Games</span>
                    </div>
                </div>
            </Nav>
        )
    }else if(location.pathname.includes('nft-collection-detail-player')){
        return <Nav className='d-flex'>
                <div className='d-flex align-items-center justify-content-center game-opt active mx-3 my-1' onClick={() => navigate(`/app/nft-collection-detail-player/${id}`)}>
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6663 11.5002V0.833496H2.99967V11.5002H13.6663ZM6.33301 7.50016L7.68634 9.30683L9.66634 6.8335L12.333 10.1668H4.33301L6.33301 7.50016ZM0.333008 3.50016V14.1668H10.9997V12.8335H1.66634V3.50016H0.333008Z" fill="black" />
                    </svg>
                    <div className="ms-2">
                        <span>Collections</span>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center game-opt my-1 cursor-pointer' onClick={() => navigate(`/app/game`)}>
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33366 6.83317H14.667V8.1665H1.33366V6.83317ZM2.10033 4.13317L2.66699 3.1465L3.23366 4.13317L4.10033 3.63317L3.53366 2.6465H4.66699V1.6465H3.53366L4.10033 0.666504L3.23366 0.166504L2.66699 1.1465L2.10033 0.166504L1.23366 0.666504L1.80033 1.6465H0.666992V2.6465H1.80033L1.23366 3.63317L2.10033 4.13317ZM6.56699 3.63317L7.43366 4.13317L8.00033 3.1465L8.56699 4.13317L9.43366 3.63317L8.86699 2.6465H10.0003V1.6465H8.86699L9.43366 0.666504L8.56699 0.166504L8.00033 1.1465L7.43366 0.166504L6.56699 0.666504L7.13366 1.6465H6.00033V2.6465H7.13366L6.56699 3.63317ZM15.3337 1.6465H14.2003L14.767 0.666504L13.9003 0.166504L13.3337 1.1465L12.767 0.166504L11.9003 0.666504L12.467 1.6465H11.3337V2.6465H12.467L11.9003 3.63317L12.767 4.13317L13.3337 3.1465L13.9003 4.13317L14.767 3.63317L14.2003 2.6465H15.3337V1.6465Z" fill="#BA9C00" />
                    </svg>
                    <div className="ms-2">
                        <span className="yellow-text hover">Games</span>
                    </div>
                </div>
            </Nav>
    }else{
        return (
            <Nav className='d-flex'>
                {/* <div className='d-flex align-items-center justify-content-center game-opt active mx-3 my-1' >
                
                </div> */}
                <div className='d-flex align-items-center justify-content-center game-opt my-1 cursor-pointer' onClick={() => navigate(`/app/nft-collection-detail-player/${id}`)} >
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6663 11.5002V0.833496H2.99967V11.5002H13.6663ZM6.33301 7.50016L7.68634 9.30683L9.66634 6.8335L12.333 10.1668H4.33301L6.33301 7.50016ZM0.333008 3.50016V14.1668H10.9997V12.8335H1.66634V3.50016H0.333008Z" fill="#BA9C00" />
                    </svg>
                   
                    <div className="ms-2 hover">
                        <span className="yellow-text hover">Collections</span>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center game-opt my-1 cursor-pointer' onClick={() => navigate(`/app/game`)}>
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33366 6.83317H14.667V8.1665H1.33366V6.83317ZM2.10033 4.13317L2.66699 3.1465L3.23366 4.13317L4.10033 3.63317L3.53366 2.6465H4.66699V1.6465H3.53366L4.10033 0.666504L3.23366 0.166504L2.66699 1.1465L2.10033 0.166504L1.23366 0.666504L1.80033 1.6465H0.666992V2.6465H1.80033L1.23366 3.63317L2.10033 4.13317ZM6.56699 3.63317L7.43366 4.13317L8.00033 3.1465L8.56699 4.13317L9.43366 3.63317L8.86699 2.6465H10.0003V1.6465H8.86699L9.43366 0.666504L8.56699 0.166504L8.00033 1.1465L7.43366 0.166504L6.56699 0.666504L7.13366 1.6465H6.00033V2.6465H7.13366L6.56699 3.63317ZM15.3337 1.6465H14.2003L14.767 0.666504L13.9003 0.166504L13.3337 1.1465L12.767 0.166504L11.9003 0.666504L12.467 1.6465H11.3337V2.6465H12.467L11.9003 3.63317L12.767 4.13317L13.3337 3.1465L13.9003 4.13317L14.767 3.63317L14.2003 2.6465H15.3337V1.6465Z" fill="#BA9C00" />
                    </svg>
                    <div className="ms-2">
                        <span className="yellow-text hover">Games</span>
                    </div>
                </div>
            </Nav>
        )
    }
}

export default Tab
