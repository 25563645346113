export const getEllipsisTxt = (str, n = 6) => {
  if (!str) return "";
  if (str.length <= n * 2) {
    return str;
  }
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};
