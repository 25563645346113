import GameCollection from '../../contracts/Game.json';
import { getAccount } from './account';
import Web3 from "web3";
import gameService from '../../services/gameService';
import playerService from '../../services/playerService';
import { blockedGameContracts } from './../Constants/Constant';

export async function getContract(address) {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(GameCollection.abi, address);
  return contract;
}

export async function buyToken(address, game, path, account , numsold) {
  const contract = await getContract(address);
  const price = await contract.methods.price().call();
  const result = await contract.methods.buy().send({
    from: await getAccount(),
    value: price
  });
  const soldId = result.events.tokenSold.returnValues.tokenId;
  Transaction.setTransaction(game, result.transactionHash);
  await updateSoldNft(game, path, soldId, account, numsold);
  return result
}

export async function countMinted(gameAddress) {
  console.log("countMinted", gameAddress)
  const contract = await getContract(gameAddress);
  const result = await contract.methods.totalSupply().call();
  return result
}

export async function updateSoldNft(game, path, soldId, account ,numSold) {
  
  const payLoad = { sold: numSold, path }
  console.log('payload', payLoad);
    await gameService.update(game._id, payLoad)
    const body = {
      word:soldId,
      game: game._id,
      username: '',
      email: '',
      walletAddress: account,
      isBought: true,
    }
    await playerService.update(body);
}

export async function reward(address, passphrase, tokenId) {
  const contract = await getContract(address);
  return await contract.methods.awards(passphrase.trim(), tokenId).send({
    from: await getAccount(),
  });
}

export async function createContract(price, phrase, gameId, activeNumber, uri) {
  const web3 = new Web3(window.ethereum);
  const walletAddress = await getAccount();
  const Contract = new web3.eth.Contract(GameCollection.abi);
  Contract.options.data = GameCollection.bytecode;

  const contract = await Contract.deploy({
    arguments: [gameId, Web3.utils.toWei(price + ''), phrase.trim(), activeNumber, uri]
  }).send({
    from: walletAddress
  });

  return { tokenAddress: contract._address, creatorAddress: walletAddress };
}

export async function getNfts(game) {
  let nfts = [];
  if(typeof game.tokenAddress !== 'undefined' &&  blockedGameContracts.indexOf(game.tokenAddress) === -1) {
    const contract = await getContract(game.tokenAddress);
    for (let i = 0; i < game.numberPlayer; i++) {
      nfts[i] = {
        tokenAddress: game.tokenAddress,
        gameId: game._id,
        creatorAddress: game.creatorAddress,
        word: contract.methods.tokenURI(i + 1).call(), // promise
        price: game.price,
        tokenId: i + 1,
        owner: contract.methods.ownerOf(i + 1).call()  // promise
      }
    }
  }
  return nfts;
}

export async function getNft(tokenAddress, tokenId) {
  const contract = await getContract(tokenAddress);
  return {
    tokenAddress: tokenAddress,
    word: await contract.methods.tokenURI(tokenId).call(),
    price: await contract.methods.price().call() / 1e18,
    tokenId,
    owner: await contract.methods.ownerOf(tokenId).call()
  }
}

export async function getTransactionsDetails(tx) {
  const web3 = new Web3(window.ethereum);
  const result = await web3.eth.getTransactionReceipt(tx);
  return result;
}

export class Transaction {
  static tx = '0';
  static setTransaction = (game, tx) => {
    this.tx = tx
    localStorage.setItem("pendingTx", JSON.stringify({game, tx}))
  };
  static getTransaction = () => this.tx;
}
