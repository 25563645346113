import React, { useEffect, useState } from "react";
// import { nftCollectionData } from "../utilities/Constants/Constant";
import { Link} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import gameService from '../services/gameService';
import "../style/nft-collection-mainpage.css";
import ReactPaginate from "react-paginate";

 


const NftCollectionMainpage = (props) => {

  const { balance, setAccount, setBalance, account } = props;
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const GamePerPage = 40;
  const pagesVisited = pageNumber * GamePerPage;
  const pageCount = Math.ceil(games.length / GamePerPage);
  async function fetchGame(page) {
    setLoading(true);
    const { data } = await gameService.list({status: true});
    setGames(data?.result);
    setLoading(false);
  }

  useEffect(() => {
    fetchGame();
    // return
  }, []);
  const changePage = ({ selected }) => {
    setLoading(true);
    setPageNumber(selected);
    setLoading(false);
  };
  if (loading) {
    return <div className="mt-131 sold-out-text">Loading....</div>
  } else
    return (
      <>
        <Header
          balance={balance}
          account={account}
          setAccount={setAccount}
          setBalance={setBalance}
        />
        <div className="banner-section">
          <div className="banner-container">
            <h1>
              Live <span className="yellow-text">COLLECTION</span>
            </h1>
            <p>
              Own an NFT from this collection to participate in the next round of
              Passphrase.
            </p>
          </div>
          {games.length === 0 ?  
          <div className="admin-no-nft-text">
            No nft available at the moment
          </div> : null }
        </div>
        <div className="tab-content">
          <div className="tab-pane active">
            <div className="row">
                {games?.slice(pagesVisited, pagesVisited + GamePerPage).map((game ) => 
                <GameSection game={game} key={game._id} account={account}/>)
                }
                {
                  games.length > 0 ? <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                /> : null
                }
               
               
            </div>
          </div>
        </div>
     
        
        <div className="row mt-192">
          <div className="col">
            <span className="footer-text">
              Pass<span className="yellow-text">Phrase</span>
            </span>
          </div>
        </div>
        <Footer />
      </>
    );
};

function GameSection({ game, account }) {
 let  { numberPlayer , price ,sold} = game;
 let remaining = numberPlayer - sold;
 let totalETH = Math.round((price * numberPlayer * 0.8) * 10000) / 10000

  return (
    
    <div className="card-wrapper">
    <Link 
    to={`/app/detail-page/${game._id}`}>
      <div className="card-item">
        <div className="card-images">
          <h2
            className={
              `text-align ${remaining === 0 ? "red-text" : "yellow-text"}`
            }
          >
            {totalETH} ETH
          </h2>
        </div>
        <div className="card-footer">
          <div className="card-footer-row">
            <div className="card-footer-tittel">PLAYERS</div>
            {remaining === 0 ? 
            <div className="red-text">SOLD OUT</div> :  <div className="yellow-text">{sold}/{numberPlayer}</div>
            }
            
          
          </div>
          <div className="card-footer-row">
            <div className="card-footer-tittel">Price</div>

            <div className="price yellow-text">
              <i className="fab fa-ethereum"></i> {price}
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
  );
}

export default NftCollectionMainpage;

