import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import metaMask from "../assets/icons/MetaMaskFox.svg";
import coinBase from "../assets/icons/coinbase.svg";
import walletConnects from "../assets/icons/walletconnect.png";
import trustWallet from "../assets/icons/trustwallet.png";
import closeIcon from "../assets/icons/closeicon.svg";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { toast } from 'react-toastify';

const ConnectWalletModal = (props) => {
  const url = process.env.RPC_URL;
  const { setAccount, setBalance } = props.actions;
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545", url);
  const { activate, account, library, chainId } = useWeb3React();
  // const [network, setNetwork] = useState('0x61');

  // useEffect(()=>{
  //   if(window.ethereum?.chainId){
  //     if(window.ethereum.chainId !== "0x3" && window.ethereum.chainId !== "0x61"){
  //       alert('Please choose Ropsten Network in your wallet header')
  //     }
  //   }
  // },[network])

  const connectMetaMaskWalletOnClick = async () => {
    if (window.ethereum !== undefined) {
          let address
          try {
            address = await window.ethereum.enable();
          localStorage.setItem("account", address);
          setAccount(await address[0]);
          } catch (error) {
            toast.error('Please sign-in to MetaMask', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // console.log({address})
          if (address && address.length > 0) {
            const balance = await web3.eth.getBalance(address[0]);
            const ethBalance = web3.utils.fromWei(balance, "ether");
            setBalance(ethBalance);
            props.onHide();
          }
    } else {
      try {

        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const connectToCoinBase = async () => {
    const walletlink = new WalletLinkConnector({
      url: url,
      appName: "passphrase",
      supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80001],
    });
    try {
      await activate(walletlink);
    } catch (ex) {
      // console.log(ex);
    }
  };

  const connectToWalletConnect = async () => {
    const walletconnect = new WalletConnectConnector({
      rpc: {
        1: url,
      },
      qrcode: true,
      pollingInterval: 12000,
    });
    try {
      await activate(walletconnect);
    } catch (ex) {
      // console.log(ex);
    }
  };

  const getBalance = async (account) => {
    const balance = await web3.eth.getBalance(account);
    const ethBalance = web3.utils.fromWei(balance, "ether");
    setBalance(ethBalance);
    if (balance) {
      props.onHide();
    }
  };

  useEffect(() => {
    if (!!account && !!library) {
      localStorage.setItem("account", account);
      getBalance(account);
    }
  }, [account, library, chainId]);

  return (
    <>
      <Modal
        className="Connect-Modal"
        {...props}
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <span className="closeButton" onClick={props.onHide}>
          <img src={closeIcon} alt="" />
        </span>

        <Modal.Body>
          <h4>Connect your wallet</h4>
          <div className="button-section">
            <button
              className="button"
              onClick={() => connectMetaMaskWalletOnClick()}
            >
              Metamask
              <span>
                <img src={metaMask} alt="" />
              </span>
            </button>
            <button className="button" onClick={() => connectToCoinBase()}>
              Coinbase
              <span>
                <img src={coinBase} alt="" />
              </span>
            </button>
            <button className="button" onClick={() => connectToWalletConnect()}>
              WalletConnect
              <span>
                <img src={walletConnects} alt="" />
              </span>
            </button>
            <button className="button">
              Trust Wallet
              <span>
                <img src={trustWallet} alt="" />
              </span>
            </button>
          </div>
          <p>
            By connecting your wallet, you agree to our{" "}
            <Link to="privacy">Terms of Service</Link> and our{" "}
            <Link to="service">Privacy Policy</Link>.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
