import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import "../../style/admin/login.css";
function AdminForgotPassword() {
  let navigate = useNavigate();
  // const [passwordActive, setPsActive] = useState(false);
  // const [buttonActive, setButtonActive] = useState(false);
  const [email, setEmail] = useState("");
  const [password] = useState("");

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const body = JSON.stringify({ email, password });
      const res = await axios.post(
        process.env.REACT_APP_API,
        body,
        config
      );
      if (!res.data.completed) {
        toast.error(res.data.message);
      } else {
        navigate("/admin/home");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-center py-4 navbar-main">
        <div className="navbar-logo">
          PASS<span className="yellow-text">PHRASE</span>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center mt-144">
        <div className="register-box d-flex flex-column w-410 ">
          <span className="register-title">Welcome, Gate Keeper</span>
          <form onSubmit={loginHandler}>
            <input
              placeholder="Email"
              type="email"
              className="input-register"
              onChange={(e) => setEmail(e.target.value)}
              required
           
            />
            <button type="submit" className={`register-button w-410`}>
              Reset Password
            </button>
          </form>

          <Link to="/admin/login" className="forgot-password">
            Sign in
          </Link>
        </div>
      </div>
    </>
  );
}

export default AdminForgotPassword;
