export const nftCollectionData = [
  {
    id: 1,
    name: 'CHRONICLE',
    image: 'CHRONICLE',
    price: 0.1,
    status: '',
  },
  {
    id: 2,
    name: 'SMOOTH',
    image: 'SMOOTH',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 3,
    name: 'JELLYFISH',
    image: 'JELLYFISH',
    price: 0.1,
    status: '',
  },
  {
    id: 4,
    name: 'DANCE',
    image: 'DANCE',
    price: 0.1,
    status: '',
  },

  {
    id: 5,
    name: 'MEMORY',
    image: 'MEMORY',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 6,
    name: 'SCORCH',
    image: 'SCORCH',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 7,
    name: 'UNEVEN',
    image: 'UNEVEN',
    price: 0.1,
    status: '',
  },
  {
    id: 8,
    name: 'SILENT',
    image: 'SILENT',
    price: 0.1,
    status: '',
  },
  {
    id: 9,
    name: 'TANGY',
    image: 'TANGY',
    price: 0.1,
    status: '',
  },
  {
    id: 10,
    name: 'EQUAL',
    image: 'EQUAL',
    price: 0.1,
    status: '',
  },
  {
    id: 11,
    name: 'CHRONICLE',
    image: 'CHRONICLE',
    price: 0.1,
    status: '',
  },
  {
    id: 12,
    name: 'SMOOTH',
    image: 'SMOOTH',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 13,
    name: 'JELLYFISH',
    image: 'JELLYFISH',
    price: 0.1,
    status: '',
  },
  {
    id: 14,
    name: 'DANCE',
    image: 'DANCE',
    price: 0.1,
    status: '',
  },

  {
    id: 15,
    name: 'MEMORY',
    image: 'MEMORY',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 16,
    name: 'SCORCH',
    image: 'SCORCH',
    price: 0.1,
    status: 'SOLD OUT',
  },
  {
    id: 17,
    name: 'UNEVEN',
    image: 'UNEVEN',
    price: 0.1,
    status: '',
  },
  {
    id: 18,
    name: 'SILENT',
    image: 'SILENT',
    price: 0.1,
    status: '',
  },
  {
    id: 19,
    name: 'TANGY',
    image: 'TANGY',
    price: 0.1,
    status: '',
  },
  {
    id: 20,
    name: 'EQUAL',
    image: 'EQUAL',
    price: 0.1,
    status: '',
  },
  {
    id: 21,
    name: 'HUGE',
    image: 'HUGE',
    price: 1,
    status: '',
  },
  {
    id: 22,
    name: 'CONTROL',
    image: 'CONTROL',
    price: 1,
    status: '',
  },
  {
    id: 23,
    name: 'CHECK',
    image: 'CHECK',
    price: 1,
    status: 'SOLD OUT',
  },
  {
    id: 24,
    name: 'PLAY',
    image: 'PLAY',
    price: 1,
    status: '',
  },
  {
    id: 25,
    name: 'WGMI',
    image: 'WGMI',
    price: 1,
    status: 'SOLD OUT',
  },
  {
    id: 26,
    name: 'CREATION',
    image: 'CREATION',
    price: 1,
    status: '',
  },
  {
    id: 27,
    name: 'TANGY',
    image: 'TANGY',
    price: 0.1,
    status: '',
  },
  {
    id: 28,
    name: 'EQUAL',
    image: 'EQUAL',
    price: 0.1,
    status: '',
  },
  {
    id: 29,
    name: 'HUGE',
    image: 'HUGE',
    price: 1,
    status: '',
  },
  {
    id: 30,
    name: 'CONTROL',
    image: 'CONTROL',
    price: 1,
    status: '',
  },
  {
    id: 31,
    name: 'CHECK',
    image: 'CHECK',
    price: 1,
    status: '',
  },
  {
    id: 32,
    name: 'PLAY',
    image: 'PLAY',
    price: 1,
    status: '',
  },
  {
    id: 33,
    name: 'WGMI',
    image: 'WGMI',
    price: 1,
    status: '',
  },
  {
    id: 34,
    name: 'CREATION',
    image: 'CREATION',
    price: 1,
    status: '',
  },
  {
    id: 35,
    name: 'HUGE',
    image: 'HUGE',
    price: 1,
    status: '',
  },
  {
    id: 36,
    name: 'CONTROL',
    image: 'CONTROL',
    price: 1,
    status: '',
  },
  {
    id: 37,
    name: 'CHECK',
    image: 'CHECK',
    price: 1,
    status: '',
  },
  {
    id: 38,
    name: 'PLAY',
    image: 'PLAY',
    price: 1,
    status: '',
  },
  {
    id: 39,
    name: 'WGMI',
    image: 'WGMI',
    price: 1,
    status: '',
  },
  {
    id: 40,
    name: 'CREATION',
    image: 'CREATION',
    price: 1,
    status: '',
  },
]

export const SHARE_LINK = 'https://passphrase.live'
export const SHARE_HASH_TAGS = '#nft #nfts #playtowin #ethereum'
export const SHARE_MSG = `Want to play a game? Come join me at @passphrase_nft`

export const checkPassPhrase = {
  exactly: 1,
  include: 2,
}

export const Regx = {
  onlyFullNumber: /^[0-9\b]+$/,
  onlyNumber: /^[0-9]+(\.[0-9]*)?$/
}

export const blockedGameContracts = [
  '0x56B59F9e572b049419837BF7954A1ea55c9Ae923'
]