import React, { useEffect, useState } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import rectangle from "../../assets/icons/rectangle.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import gameService from "../../services/gameService.js";
import "../../style/app/instructions.css";
import "../../style/responsive.css";
import Tab from "../../components/Tab";

import { getNfts } from "../../utilities/Web3/contract.js";
// const useInterval = (callback, delay) => {
//     const savedCallback = useRef();

//     useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);

//     useEffect(() => {
//         const tick = () => {
//             savedCallback.current();
//         }
//         if (delay !== null) {
//             let id = setInterval(tick, delay);
//             return () => clearInterval(id);
//         }
//     }, [delay]);
// };

const Instructions = (props) => {
  let [container] = useState(false);
  const { gameId } = useParams();
  const { balance, setAccount, setBalance, account } = props;
  const [user, setUser] = useState("");

  let WithContainer = ({ children }) => {
    return (
      // <>{container ? ( <div className="container-main">{children}</div> ) : ( children )} </> );
       <div className="container-main">{children}</div> ) 
  };

  return (
    <WithContainer>
      <Header
        backArrow
        midComponents={<Tab gameId={gameId} />}
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
        user1={user}
        gameIdAsProp={gameId}
      />
      <InstructionContainer {...props} />
      <Footer />
    </WithContainer>
  );
};

const InstructionContainer = (props) => {
  let navigate = useNavigate();
  const [total, setTotal] = useState(12);
  const [filled, setFilled] = useState(0);
  const { gameId } = useParams();
  const [game, setGame] = useState();

  // const [user, setUser] = useState("");

  const gameStartHour =  24 * 3600 * 1000; // seconds for 24 hours

  async function fetchGame() {
    try {
      
      const { data } = await gameService.view(gameId);
      if (data) {
        setGame(data.result);
        setTotal(data.result.numberPlayer);
        // setFilled(data.result.numberPlayer)
        setFilled(data?.result.sold);
        // setNftsFiltered(nftList);
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // async function getSoldIds() {
  //   const ids = [];
  //   let i = 0;
  //   for await (const nft of nfts) {
  //     const nftOwner = await nft.owner;
  //     if (+nftOwner !== +game.creatorAddress) {
  //       ids.push(nft.tokenId);
  //       setFilled(++i);
  //     }
  //   }
  //   setSoldIds(ids);
  // }

  // useEffect(() => {
  //   if (nfts) getSoldIds();
  //   const root = document.getElementById("root");
  //   // console.log('root',root.childNodes[0].classList[0]);
  //   root.childNodes[0].classList.add("container-main");
  // }, [nfts]);

  useEffect(() => {
    fetchGame();
  }, [gameId]);

  // console.log('account', account)

  const [hour, setHour] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  let [timeleft, setTimeleft] = React.useState(86400); //86400 sec
  // const timeinterval = 500; //500 sec for test, real: 1 sec
  const timeinterval = 1;

  useEffect(() => {
    if (total === filled) {
      if(game.timeStart === undefined || !game.timeStart) { // if filled is diffeent with sold number in db
        game.timeStart = new Date().getTime();
        //update sold number, timestart
        const location = window.location.href
        const path =
        location.split('app')[0] + 'app/instructions/' + gameId.split('&')[0]
        const payLoad = {
          sold: game.numberPlayer,
          path,
        }
        gameService.update(game._id, payLoad);
      }

      setTimeleft(game.timeStart);
      const date = new Date().getTime();
      if (date > game.timeStart + gameStartHour) {
        timeleft = 0;
      } else {
        timeleft = Math.floor((game.timeStart + gameStartHour - date) / 1000);

      }
    } else {
      setTimeleft(-1000);
    }
  }, [game, total, filled]);

  useEffect(() => {
    if (total === filled) {
      const timer = setTimeout(() => {
        const newIntervalId2 = setInterval(() => {
          timeleft -= timeinterval;
          if (timeleft <= 0) {
            timeleft = 0;
          }
          let hour = Math.floor(timeleft / 3600);
          let min = Math.floor((timeleft % 3600) / 60);
          let sec = (timeleft % 3600) % 60;
          setTimeleft(timeleft);
          setHour(hour);
          setMinutes(min);
          setSeconds(sec);
          if (timeleft === 0) {
            clearInterval(newIntervalId2);
          }
        }, 1000);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [game, total, filled]);


  return (
    <div className="mtb" id="Instructions">
      <Row>
        <Col md={12} lg={6} sm={12} xs={12} className="mb-5">
          <svg
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 4H0V6H11V4Z" fill="#FFD600" />
            <path d="M11 0H0V2H11V0Z" fill="#FFD600" />
            <path d="M7 8H0V10H7V8Z" fill="#FFD600" />
            <path d="M13 7V15L19 11L13 7Z" fill="#FFD600" />
          </svg>
          <h1 className="yellow-text title aligninst">Instructions</h1>
        </Col>
        <Col md={12} lg={6} sm={12} xs={12}>
          <div className="d-flex mb-5 step-container">
            <span className="steps w-25">Step 1</span>
            <p className=" w-75 stepdesc">
              You have purchased the NFT to participate in the game. You can
              always log into your account with your wallet.
            </p>
          </div>
          <div className="d-flex mb-5 step-container">
            <span className="steps w-25">Step 2</span>
            <p className="w-75 stepdesc">
              As soon as the rest of the players purchase the remaining NFTs in
              the collection, the countdown to join the game begins. We will
              remind you 1 hour before the game starts.
            </p>
          </div>
          <div className="d-flex mb-5 step-container">
            <span className="steps w-25">Step 3</span>
            <p className="w-75 stepdesc">
              Try to guess the correct passphrase of the wallet. If you are the
              lucky winner, you'll win the prize pool.
            </p>
          </div>
        </Col>
        <img src={rectangle} className="mt-1" alt="" />
      </Row>
      <Row className="mt-5">
        <Col md={12} lg={6} sm={12} xs={12} className="mb-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4 6L14 4H5V21H7V14H12.6L13 16H20V6H14.4Z"
              fill="#FFD600"
            />
          </svg>
          <h1 className="yellow-text title aligninst">Status</h1>
        </Col>
        <Col md={12} lg={6} sm={12} xs={12} className="mt-5">
          <div className="d-flex">
            <ProgressBar
              now={Math.floor((filled * 100) / total)}
              className="w-100"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between slots">
            {total === filled ? (
              <div className="mt-1 w-100">
                <div className="d-flex justify-content-between">
                  <span className="p-1">
                    All slots have been filled. The game starts in:{" "}
                  </span>
                </div>
                <div className="steps mt-3">
                  {hour < 10 ? `0${hour}` : hour}:{" "}
                  {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                  {seconds < 10 ? `0${seconds}` : seconds}
                </div>
              </div>
            ) : (
              <div className="mt-1 w-100">
                <div className="d-flex justify-content-between">
                  <span className="p-1">{filled} slots filled</span>
                  <span className="p-1">{total - filled} slots remaining</span>
                </div>
                <div className="steps mt-3"></div>
              </div>
            )}
          </div>
          <div className="d-flex join">
            <Button
              onClick={() => navigate(`/app/passphrase/${gameId}`)}
              className={`w-100 join-btn ${timeleft === 0 ? "active" : ""}`}
              disabled={timeleft !== 0}
              // className={`w-100 join-btn ${timeleft === 0 ? "active" : "active"}`}
              size="lg"
            >
              Join the game now
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Instructions;
