import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import "../style/register.css";

function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        password,
        walletAddress,
        twitterURL,
        contactNumber,
      });
      const res = await axios.post(
          `${process.env.REACT_APP_API}users`,
        body,
        config
      );
      if (!res.data.completed) {
        toast.error(res.data.message);
      } else {
        navigate("/admin/login");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // document.querySelector(".navbar-login").style.display="none";
    // document.querySelector(".navbar-main").classList.add("justify-content-center-f")
  }, []);

  return (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="register-box">
          <form className=" d-flex flex-column w-410" onSubmit={handleSubmit}>
            <span className="register-title">
              Welcome!
              <br /> Let’s get you Ready for the game
            </span>
            <input
              placeholder="Username"
              required
              type="text"
              className="input-register"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              placeholder="Password"
              required
              type="password"
              className="input-register"
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              placeholder="Wallet address"
              required
              className="input-register"
              onChange={(e) => setWalletAddress(e.target.value)}
            />
            <input
              placeholder="Email address"
              required
              className="input-register"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="input-optional">
              <span className="input-optional-text">Optional</span>
              <input
                onChange={(v) => {
                  setTwitterURL(v.target.value) && v.target.value
                    ? (document.querySelector(
                        ".input-optional-text"
                      ).style.display = "none")
                    : (document.querySelector(
                        ".input-optional-text"
                      ).style.display = "unset");
                }}
                placeholder="Twitter URL"
                type="url"
                className="input-register"
              />
            </div>
            <div className="input-optional">
              <span className="input-optional-text-2">Optional</span>
              <input
                onChange={(v) => {
                  setContactNumber(v.target.value) && v.target.value
                    ? (document.querySelector(
                        ".input-optional-text-2"
                      ).style.display = "none")
                    : (document.querySelector(
                        ".input-optional-text-2"
                      ).style.display = "unset");
                }}
                placeholder="Number"
                className="input-register"
              />
            </div>
            <button className="register-button w-410" type="submit">
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;
