import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import '../style/nft-collection.css'
// import NftCollectionCard from '../components/nftCollectionCard'
// import { getNfts } from '../utilities/Web3/contract.js'
import gameService from '../services/gameService'
// import backArrow from '../assets/icons/backarrow.svg'
import Tab from '../components/Tab'

const PlayerGame = (props) => {
  const { balance, setAccount, setBalance, account } = props
  // const params = useParams()
  // const [nftsFiltered, setNftsFiltered] = useState([])
  // const [soldIds, setSoldIds] = useState([])
  // const [nfts, setNfts] = useState([])
  // const [dataLimit, setDataLimit] = useState(16)
  const [game, setGame] = useState([])
  // const [filter, setFilter] = useState(0)
  // const filterText = ['View all', 'Sold', 'UnSold']
  // const [isOwner, setIsOwner] = useState(false)
  const [past, setPast] = useState()
  const [liveGames, setLiveGames] = useState([])
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate()
  const [id, setId] = useState('')
  async function fetchGame() {
    const { data } = await gameService.getByAccount(account)
    setGame(data?.result)
    if (data?.result.length > 0) {
      setId(data?.result[0]._id)
    }

    setPast(false)
    setLoading(false)
  }


  useEffect(() => {
    const arrGame = [...game]
    if (past) {
      setLiveGames(arrGame.filter((game) => game.status === false))
    } else {
      setLiveGames(arrGame.filter((game) => game.status === true))
    }
  }, [game, past])

  useEffect(() => {
    fetchGame()
  },[])

  return (
    <>
      <div>
        <Header
          midComponents={<Tab gameId={id} account={account} loading={loading} />}
          balance={balance}
          account={account}
          setAccount={setAccount}
          setBalance={setBalance}
          gameIdAsProp={id}
        />
        <div className='admin-live-nav-text'>
          <span
            className='me-4 hover'
            onClick={() => {
              setPast(false)
            }}
          >
            Live games
          </span>
          <span
            className='hover'
            onClick={() => {
              setPast(true)
            }}
          >
            Past games
          </span>
        </div>
        <div className='admin-live-nav-bar'>
          {!past ? (
            <div className='admin-bar-active'></div>
          ) : (
            <div className='admin-bar-active-past'></div>
          )}
        </div>
        { loading ?   <div className="mt-131 sold-out-text wait-text">Loading
         <div className="stage"><div className="dot-pulse"></div></div></div>  : liveGames.length > 0 ? (
          <div className='game-container d-flex'>
            {liveGames.map((val, index) => (
              <div
                id={val._id}
                key={index}
                onClick={() => {
                  if (val.status) {
                    navigate(`/app/instructions/${val._id}`)
                  }
                  // gameDeatils(val._id);
                }}
                className='game-card m-2'
              >
                <div className='game-card-title'>
                  {val.numberPlayer} <br />
                  players
                </div>
                <table className='mt-65'>
                  <tbody>
                    {val.status && (
                      <tr>
                        <td>
                          <img src='/game-4.png' alt='' />
                        </td>
                        <td className='game-table-text'>No winner yet</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <img src='/game-1.png' alt='' />
                      </td>
                      <td className='game-table-text'>{val.sold} NFTs sold</td>
                    </tr>
                    <tr>
                      <td>
                        <img src='/game-2.png' alt='' />
                      </td>
                      <td className='game-table-text ps-1'>
                        {val.status
                          ? val.timeStart
                            ? `Started ${Math.floor(
                                (new Date().getTime() - val.timeStart) /
                                  1000 /
                                  60
                              )} Minutes ago`
                            : 'Not start'
                          : 'The game has a winner'}
                      </td>
                    </tr>
                    {/* <tr>
                    <td>
                      <img src="/game-3.png" alt="" />
                    </td>
                    <td className="game-table-text ps-1">{val.link}</td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className='mt-131 sold-out-text no-past-game'>
              Please, purchase nft to play game!
            </div>
            <div className='button-row'>
              <div
                className='navbar-login cursor-pointer mt-40 cursor hover'
                onClick={() => navigate('/app/nft-collection-mainpage')}
              >
                Buy NFT
              </div>
            </div>
          </>
        )}
        <div className='row mt-192'>
          <div className='col'>
            <span className='footer-text'>
              Pass<span className='yellow-text'>Phrase</span>
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default PlayerGame
