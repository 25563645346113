import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/card";
import Card2 from "../components/card2";
import Footer from "../components/Footer";
import Header from "../components/Header";
import gameService from "../services/gameService";
import "../style/admin/home.css";
function Home(props) {
  const { balance, setAccount, setBalance, account } = props;
  // const [cardCount, setCardCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if(!document.querySelector(".container-main")) {
      document.body.classList.add("container-main")
    }

    // if (window.innerWidth < 1674 && window.innerWidth > 1250) {
    //   setCardCount(3);
    // }
    // if (window.innerWidth < 1360 && window.innerWidth > 1058) {
    //   setCardCount(2);
    // }
    // if (window.innerWidth < 1058) {
    //   setCardCount(1);
    // }
    // window.addEventListener("resize", function (e) {
    //   if (window.innerWidth < 1674 && window.innerWidth > 1250) {
    //     setCardCount(3);
    //   }
    //   if (window.innerWidth < 1360 && window.innerWidth > 1058) {
    //     setCardCount(2);
    //   }
    //   if (window.innerWidth < 1058) {
    //     setCardCount(1);
    //   }
    // });
      document.addEventListener("scroll", function (e) {
        if (window.innerWidth > 930) {
          const lastKnownScrollPosition = window.scrollY;
          if (lastKnownScrollPosition > 700) {
            if(document.querySelectorAll(".card2-settings").length > 0){
            document.querySelectorAll(".card2-settings")[0].style.opacity = 0;
            document.querySelectorAll(".card2-settings")[1].style.opacity = 0;
            }
          }
          if (lastKnownScrollPosition > 1100) {
            if(document.querySelectorAll(".card2-text").length > 0){
            document.querySelectorAll(".card2-text")[1].style.backgroundColor =
              "red";
            document.querySelectorAll(".card2-text")[1].style.position =
              "absolute";
            document.querySelectorAll(".card2-text")[1].style.top = "635px";
            document.querySelectorAll(".card2-text")[1].style.color = "gray";
            document.querySelectorAll(".card2-text")[1].style.opacity = "0";
            }
          }
          if (lastKnownScrollPosition > 1200) {
            if(document.querySelector(".key-img")){
              document.querySelector(".key-img").style.right = "calc(50% - 60px)";
            }
            setTimeout(() => {
              if(document.querySelector(".key-text")){
                document.querySelector(".key-text").style.opacity = 0;
              }
            }, 300);
            setTimeout(() => {
              if(document.querySelector(".key-img")){
                document.querySelector(".key-img").classList.add("rotated");
              }
            }, 500);
          }
          if (lastKnownScrollPosition > 1900) {
            if(document.querySelector(".key-img")){
            document.querySelector(".key-img").style.top = "280px";
            }
          }
          //glare
          if (lastKnownScrollPosition > 1950) {
            let glareList = document.querySelectorAll(".word-glare");
            let j = 0;
            const wordGlareInterval = setInterval(() => {
                j++;
                for (let i = 0; i < glareList.length; ++i) {
                  if(j%2)
                    glareList[i].classList.add('active');
                  else
                    glareList[i].classList.remove('active');
                }
                if(j>=5) {
                  clearInterval(wordGlareInterval);
                }
            }, 1000);
          }
          if (lastKnownScrollPosition > 2300) {
            if(document.querySelector(".eth-crown")){
              document.querySelector(".eth-crown").style.top = "110px";
              document.querySelector(".eth-crown").style.opacity = 1;
            }
            if(document.querySelector(".eth-crown-p")){
              document.querySelector(".eth-crown-p").style.top = "160px";
              document.querySelector(".eth-crown-p").style.opacity = 1;
            }
          }
        } else {
          const lastKnownScrollPosition = window.scrollY;
          if (lastKnownScrollPosition > 1900) {
            if(document.querySelectorAll(".card2-settings").length > 0){
              document.querySelectorAll(".card2-settings")[0].style.opacity = 0;
              document.querySelectorAll(".card2-settings")[1].style.opacity = 0;
            }
          }
          if (lastKnownScrollPosition < 1900) {
            if(document.querySelectorAll(".card2-settings").length > 0){
            document.querySelectorAll(".card2-settings")[0].style.opacity = 1;
            document.querySelectorAll(".card2-settings")[1].style.opacity = 1;
            }
          }
          if (lastKnownScrollPosition > 2600) {
            if(document.querySelector(".mobile-key-ani")){
              document.querySelector(".mobile-key-ani").style.right = "0px";
            }
            setTimeout(() => {
              if(document.querySelector(".mobile-key-text")){
                document.querySelector(".mobile-key-text").style.opacity = 0;
              }
            }, 300);
            setTimeout(() => {
              if(document.querySelector(".mobile-key-ani")){
                document.querySelector(".mobile-key-ani").classList.add("rotated");
              }
            }, 500);
          }
          if (lastKnownScrollPosition > 3300) {
            if(document.querySelector(".mobile-key-ani")){
              document.querySelector(".mobile-key-ani").style.top = "260px";
            }
          }
          if (lastKnownScrollPosition > 4200) {
            if(document.querySelector(".eth-crown")){
              document.querySelector(".eth-crown").style.top = "160px";
              document.querySelector(".eth-crown").style.opacity = 1;
            }
          }
        }

      });
  }, []);

  const [liveGames, setLiveGames] = useState([])
  useEffect(() => {
    const fetchGame = async () => {
      const { data } = await gameService.list({ status: true })
      let arr = [];
      if (data?.result?.length > 3) {
        arr = [data?.result[0], data?.result[1], data?.result[2]]
      } else {
        arr = data.result;
      }
      setLiveGames(arr);
    }
    fetchGame()
  }, [])

  return (
    <div>
      <Header
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className="d-flex flex-column">
        <span className="home-main-text ">
          WANT TO PLAY <br />A <span className="yellow-text">GAME?</span>
        </span>
        <span className="home-secondary-text mt-2">
          Get the right passphrase and unlock the winnings stored in a wallet.
        </span>
        {
          
        }
        <div className="card-list d-flex">
          {
            liveGames.length === 0 ? 
            <span className="home-no-game-text">
              There are currently <br/> no games available
            </span> : liveGames?.map((game, index) =>
              <Card
                key={game._id}
                className="w-150"
                img={`./card-img/${index + 1}.png`}
                gameId={game._id}
                players={game.numberPlayer}
                floorPrice={Math.round(game.price * game.numberPlayer * 100) / 100}
                liveFloorPrice={Math.round((game.price * game.numberPlayer * 0.8) * 10000) / 10000}
                clicktoredirect={true}
                price={game.price}
                // onClick={ () =>  navigate(`/app/nft-collection-mainpage`)}
              />)
          }
          {/* <Card
            className="w-150"
            img="./card-img/1.png"
            players="50"
            floorPrice="5"
            liveFloorPrice="3.2"
            clicktoredirect={true}
          />
          {
            <Card
              img="./card-img/2.png"
              players="50"
              floorPrice="5"
              liveFloorPrice="3.2"
            />
          }
          {
            <Card
              img="./card-img/3.png"
              players="50"
              floorPrice="5"
              liveFloorPrice="3.2"
            />
          }
          {cardCount >= 4 && (
            <Card
              img="./card-img/3.png"
              players="50"
              floorPrice="15"
              liveFloorPrice="3.2"
            />

          )}
          <Card
              img="./card-img/3.png"
              players="50"
              floorPrice="15"
              liveFloorPrice="3.2"
            />
            <Card
              img="./card-img/3.png"
              players="50"
              floorPrice="15"
              liveFloorPrice="3.2"
            /> */}
          <img className="card-cube" src="./card-img/cube.png" alt=""></img>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col">
          <span className="home-main-text">
            HOW DOES IT <br />
            <span className="yellow-text">WORK?</span>
          </span>
          <div className="info-card mt-288 mobile-center">
            <span>LEVEL 1</span>
            <br />
            <span className="yellow-text info-card-title">
              WE MINT, YOU ACQUIRE
            </span>
            <br />
            <p className="info-card-explain mt-48">
              Anytime a new game segment opens up, it is inaugurated by a
              freshly minted NFT collection. To participate in the game, you
              simply acquire these NFTs. As soon as all of the NFTs are sold
              out, the game begins (don’t worry about missing out on it, we give
              all the players 24 hours to get ready.
            </p>
            <div className="mobile-only">
              <Card2 text="chronice" price="0.1" />
            </div>
          </div>
          <div className="info-card mt-288 mobile-center">
            <span>LEVEL 2</span>
            <br />
            <span className="yellow-text info-card-title">NFT == Access</span>
            <br />
            <p className="info-card-explain mt-48">
              The purchase of your NFT reveals a clue and instructions on how to
              participate. So make sure you sign up and register so you're
              notified when the game starts
            </p>
            {/* <img className="mobile-only" src="./card-img/m-key.png"/> */}
            <div className="mt-288 mobile-only mobilekey-cover">
              <span className="yellow-text font-minecraft mt-3 mobile-only mobile-key-text">
                Chronice
              </span>
              <img
                src="./card-img/key.png"
                className="mobile-only mobile-key-ani"
                alt=""
              ></img>
            </div>

            <div className="mt-285 z-m mobile-only">
              <div className="dotted-line"></div>
              <div className="row">
                <div className="col">
                  <div className="pass-box active">Sneeze</div>
                </div>
                <div className="col">
                  <div className="pass-box">Entertain</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Stick</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box active">Private</div>
                </div>
                <div className="col">
                  <div className="pass-box word-glare">Foamy</div>
                </div>
                <div className="col">
                  <div className="pass-box">Parties</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box">Numerous</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Weather</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Dance</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box word-glare">Overjoyed</div>
                </div>
                <div className="col">
                  <div className="pass-box">Beef</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Tangy</div>
                </div>
              </div>
            </div>
          </div>
          <div className="info-card mt-288 mobile-center">
            <span>LEVEL 3</span>
            <br />
            <span className="yellow-text info-card-title">
              Decode passphrase
            </span>
            <br />
            <p className="info-card-explain mt-48">
              If you are the lucky winner that has guessed the right passphrase,
              then you have won the game, congratulations! But it doesn’t end
              here. The passphrase you rightly guessed unlocks the prize pool
              and will be transferred directly to your connected wallet.
            </p>
          </div>
          <div className="info-card mt-288 mobile-center">
            <span>Final level</span>
            <br />
            <span className="yellow-text info-card-title">
              Get your Winnings
            </span>
            <br />
            <p className="info-card-explain mt-48">
              If you are the lucky winner that has guessed the right passphrase,
              then you have won the game, congratulations! But it doesn’t end
              here. The passphrase you rightly guess is the passphrase of the
              wallet that has all your winnings. Use the passphrase to get your
              winnings and make them your own.
            </p>
            <div className="eth-crown-c mobile-center mt-4 mobile-only">
              <img
                className="eth-crown mobile-crown"
                src="./card-img/crown.png"
                alt=""
              ></img>
              <img className="mt-170" src="./card-img/eth-gold.png" alt=""></img>
            </div>
          </div>
        </div>
        <div className="col pc-only">
          <div className="black-box w-100 d-flex align-items-center flex-column">
            <div className="pt-356">
              <Card2 text="chronice" price="0.1" />
            </div>
            <div className="mt-316 d-flex cont-key">
              <span className="yellow-text font-minecraft mt-3 key-text">
                Chronice
              </span>
              <img src="./card-img/key.png" className="key-img" alt=""></img>
            </div>
            <div className="mt-285 z-m">
              <div className="dotted-line"></div>
              <div className="row">
                <div className="col">
                  <div className="pass-box active">Sneeze</div>
                </div>
                <div className="col">
                  <div className="pass-box">Entertain</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Stick</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box active">Private</div>
                </div>
                <div className="col">
                  <div className="pass-box word-glare">Foamy</div>
                </div>
                <div className="col">
                  <div className="pass-box">Parties</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box">Numerous</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Weather</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Dance</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="pass-box word-glare">Overjoyed</div>
                </div>
                <div className="col">
                  <div className="pass-box">Beef</div>
                </div>
                <div className="col">
                  <div className="pass-box active">Tangy</div>
                </div>
              </div>
            </div>
            <div className="eth-crown-c d-flex align-items-center justify-content-center mt-4">
              <img className="eth-crown-p" src="./card-img/crown.png" alt=""></img>
              <img className="mt-170" src="./card-img/eth-gold.png" alt=""></img>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col d-flex flex-column">
          <span className="home-main-text">
            HOW MUCH <br />
            CAN YOU <br />
            <span className="yellow-text">WIN?</span>
          </span>
          <span className="mt-48">
            Our winning prizes range from 1 ETH all the way up to 1000 ETH for
            the serious dare devils.
          </span>
          <div className="join-button cursor"
          onClick={()=>{
            navigate('/app/nft-collection-mainpage')
          }}>Join a game</div>
        </div>
        <div className="col">
          <div className="black-box-2 d-flex align-items-center justify-content-center w-100">
            <div className="d-flex flex-column sub-title-win align-self-start">
              UP TO
              <span className="yellow-text title-win ">1000 ETH</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mt-192">
        <div className="col eth-d-main">
          <img
            className="eth-d"
            width="288px"
            height="288px"
            src="./card-img/eth-d.png"
            alt=""
          />
          <div className="black-box-2 w-100 d-flex align-items-center flex-column">
            <div className="black-box-wrapper">
              <span className="title-win">
                Want to be apart of
                <br />
                <span className="yellow-text title-win">Passphrase?</span>
              </span>
              <div className="d-flex subs-input-group" 
             >
                <div className="subs-button"
                      // to={{ pathname: "https://app.uniswap.org/#/swap?inputCurrency=0xec1b541e4705e7eddf7812086f39f5b227f49d5c" }}
                      // target="_blank"
                      onClick={()=>{
                        window.open('https://app.uniswap.org/#/swap?inputCurrency=0xec1b541e4705e7eddf7812086f39f5b227f49d5c','blank');
                        
                      }}
                >
                  Buy on Uniswap
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row mt-192">
        <div className="col">
          <span className="footer-text">
            Pass<span className="yellow-text">Phrase</span>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
