import React from "react";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="row mt-36 mb-51">
        <div className="d-flex w-100 mobile-footer">
          <div className="col-1 footer-l">
            <div className="yellow-text footer-labels ">
              <img width="22px" height="22px" src="/card-img/fh.png" alt="" />
              <span className="ms-2 hover"
              onClick={() => navigate("/info/terms")}
                   >Terms</span>
            </div>
          </div>
          <div className="col-1 footer-l">
            <div className="yellow-text footer-labels">
              <img width="22px" height="22px" src="/card-img/fl.png" alt="" />
              <span className="ms-2 hover"
                     onClick={() => navigate("/info/privacy")}
                    >Privacy</span>
            </div>
          </div>
          <div className="col-1 footer-l">
            <div className="yellow-text footer-labels">
              <img width="22px" height="22px" src="/card-img/fp.png" alt="" />
              <span className="ms-2 hover"
                    onClick={() => navigate("/info/whitepaper")}>Whitepaper</span>
            </div>
          </div>
          <div className="col float-end right-col">
            <i className="far fa-copyright me-2"></i>All rights reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
