import { useNavigate } from "react-router-dom";

function Card(props) {
  const navigate = useNavigate();
  const navigateToPage = () => {
    if (props.clicktoredirect === true) {
      navigate(`/app/nft-collection-mainpage`);
    }
  };
  return (
    <>
      <div className="card main-card" onClick={() => navigateToPage()}>
        <div className="card-images">
                <h2 className="yellow-text centered">
                  {props.liveFloorPrice} ETH
                </h2>
        </div>
        <div className="card-text-content">
          <table className="w-100">
            <tbody>
              <tr className="w-100 card-tr">
                <td className="float-start">PLAYERS</td>
                <td className="float-end">
                  <span className="yellow-text">{props.players}</span>
                </td>
              </tr>
              <tr className="w-100 card-tr">
                <td className="float-start">FLOOR PRIZE</td>
                <td className="float-end">
                  <span className="yellow-text">
                    <i className="fab fa-ethereum"></i> {props.liveFloorPrice}
                  </span>
                </td>
              </tr>
              <tr className="w-100 card-tr d-none">
                <td className="float-start">LIVE FLOOR PRIZE</td>
                <td className="float-end">
                  <span className="yellow-text">
                    <i className="fab fa-ethereum"></i> {props.floorPrice}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Card;
