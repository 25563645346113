import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../style/register-done.css";

function RegisterDone() {
  const TIME_DURATION = 5000;
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/admin/login");
    }, TIME_DURATION);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-center game-countdown ">
        <div className="d-flex flex-column">
          <span className="game-countdown-title">
            Thanks for registering! <br />
            The game starts in:
          </span>
          <span className="game-countdown-time">49:53</span>
          <span className="game-countdown-subtitle">
            Best of luck, we hope you have fun!
          </span>
        </div>
      </div>
    </>
  );
}

export default RegisterDone;
