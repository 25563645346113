import axios from "axios";
// import { cleanObject } from "helper/cleanObject";

const gameService = {
  async list(limit) {
    // let searchParams = new URLSearchParams(cleanObject(filter));
    const result = await axios.get(`/words?limit=${limit}`);
    return result
  },
  // async list(filter = {}) {
  //   let searchParams = new URLSearchParams(cleanObject(filter));
  //   return await axios.get("/categories?" + searchParams.toString());
  // },
  async create(payload) {
    return await axios.post("/games", payload);
  },
  async remove(id) {
    return await axios.delete("/games/" + id);
  },
  async view(id) {
    return await axios.get("/games/" + id);
  },
  async update(id, payload) {
    return await axios.patch("/games/" + id, payload);
  },
};

export default gameService;
