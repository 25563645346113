import { useEffect, useState } from "react";
import "../../style/app/passphrase.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { io } from "socket.io-client";
import Confetti from "./Confetti";
import BackArrow from "../../components/BackArrow";
import gameService from '../../services/gameService';
import playerService from '../../services/playerService';
import { reward } from '../../utilities/Web3/contract';
function Passphrase(props) {
  const [checkPhrase, setCheckPhrase] = useState(false);
  const [wrongPhrase, setWrongPhrase] = useState(false);
  const [truePhraseB, setTruePhraseB] = useState(false);
  const [recentGuess, setRecentGuess] = useState();
  const [phraseList, setPhraseList] = useState([]);
  const { gameId } = useParams();
  const [game, setGame] = useState();
  const [USD, setUSD] = useState();
  const [lost, setLost] = useState(false);
  const { account } = props;
  const [ indexPhrase, setIndexPhrase] = useState([]);

  const [wait, setWait] = useState(false);
  const [player, setPlayer] = useState();
  let navigate = useNavigate();
  const [activeKeys, setActiveKeys] = useState([]);
  useEffect(() => {
    if (document.querySelector(".container-main")) {
      document
        .querySelector(".container-main")
        .classList.remove("container-main");
    }
  }, []);

  const looseHandler = () => {
    setLost(true);
    setTimeout(() => {
      navigate('/')
    }, 6000)
  }

  const winPassPhrase = () => {
    const correctPassPhrase = activeKeys.toString().replaceAll(",", " ");

    reward(game.tokenAddress, correctPassPhrase ,player.tokenId)
    gameService.update(gameId, { status: false })
    //send alert for other player
    setTimeout(() => {
      toast.success('We sent award to your wallet');
      looseHandler();
    }, 1000)
  }

  const fetchGame = async () => {
    const { data } = await gameService.view(gameId);
    setGame(data.result)
    const date = new Date().getTime();
    if (!data.result.status) {
      navigate('/')
    } else if (data.result.timeStart + ( 24 * 3600 * 1000)> date) {
      navigate(`/app/instructions/${gameId}`)
    }
    setPhraseList(data.result.randomWord)
    // setTruePhrase(data.result.passPhrase)
  }

  const fetchPlayer = async () => {
    const { data } = await playerService.checkPlayer({game: gameId, walletAddress: account});
    if(!data.result){
      toast.error('You can not play this game. Please purchase another NFT to play game');
      navigate(`/app/instructions/${gameId}`)
    }
    setPlayer(data.result)
  }
  useEffect(() => {
    fetchGame()
    fetchPlayer()
  }, [gameId])

  const checkPassPhare = async() => {
    const { data } = await gameService.checkPassPhrase(gameId, {currentGuess: activeKeys});
    return data;
  }

  const resetButtonCheck = () =>
  {
    if(activeKeys.length === 0 && checkPhrase){
      setCheckPhrase(false)
      setWrongPhrase(false)
    }
  }
  const checkHandler = async () => {
    if(activeKeys.length === 12 ) {
      setWait(true);
      const checkTrue = await checkPassPhare();
      setIndexPhrase(checkTrue.guess);
      setWait(false);
      // if(checkTrue.pass.length > 0){
      //   setPassGuess(checkTrue.pass)
      // }else{
      //   setPassGuess(checkTrue.guess)
      // }
      setRecentGuess([...activeKeys]);
      setActiveKeys([]);
      if (wrongPhrase) {
        setActiveKeys([]);
        document.querySelector(".check-button").style.color = "black";
        setWrongPhrase(false);
        setCheckPhrase(false);
        setRecentGuess(activeKeys);
      } else {
        if (checkTrue.result) {
          setTruePhraseB(true);
          //set winPassPhrase
          winPassPhrase();
        } else {
          setWrongPhrase(true);
        }
        setCheckPhrase(true);
      }
      
    }
  };

  useEffect(() => {
    const exchangeEthToUSD = async () => {
      const { data } = await gameService.exchangeEthToUSD();
      setUSD(data.USD);
    }

    exchangeEthToUSD()
  }, [game])

  useEffect(() => {
    const exchangeEthToUSD = async () => {
      const { data } = await gameService.exchangeEthToUSD();
      setUSD(data.USD);
    }
    const getChangeUSD = setInterval(exchangeEthToUSD, 60000);

    var socket = io(process.env.REACT_APP_SOCKET_SERVER);
    socket.on('connect', () => {
        console.log("Socket connect");
    });

    socket.emit('join-game', gameId);
    socket.on("end-game", function() {
      if(!wait) {
        setTruePhraseB(false);
        setLost(true);
        console.log("end-game");
      }
    })
    socket.on('room-joined', function(user){
      console.log("room-joined by ", user);
    })

    return () => {
      clearInterval(getChangeUSD)
    }
  }, [])

  return (
    <>
      <div className="d-flex main-phrase">
        <div className="w-50 pl-96 pt-24 main-phrase-wm">
          <div className="navbar-logo">
            <BackArrow />
            PASS<span className="yellow-text">PHRASE</span>
          </div>
          <div className="phrase-title">Choose your first word</div>
          <div className="phrases">
            {phraseList.map((v, i) => (
              <div
                key={i}
                onClick={() => {
                  resetButtonCheck()
                  if (!activeKeys.includes(v)) {
                    activeKeys.length < 12 && setActiveKeys([...activeKeys, v]);
                  } else {
                    const index = activeKeys.findIndex(k => k === v);
                    const arr = [...activeKeys];
                    arr.splice(index, 1);
                    setActiveKeys(arr)
                  }
                }}
                className={`phrase-word ${activeKeys.includes(v) && "active-word-phrase"
                  }`}
              >
                {v}
              </div>
            ))}
            <div></div>
          </div>
        </div>
        <div className="w-50 d-flex flex-column check-p pr-96 pt-24 main-phrase-wm">
          <div className="d-flex justify-content-between align-items-center pl-96">
            <div className="font-minecraft-p">
              <i className="fab fa-ethereum yellow-color me-2"></i>{Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000}{" "}
              <span className="font-minecraft-p color-gray ms-2">
                {Math.round(USD * (Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000) * 1000) / 1000} USD
              </span>
            </div>
            <div className="font-minecraft-p">
              {" "}
              {truePhraseB && !lost ? (
                <div className="d-flex align-items-center">
                  <div className="circle-green"></div> WINNER
                </div>
              ) : !lost && (
                <>
                  <img src="/rec.png" alt="" /> OPEN
                </>
              )}
              {lost && <div className="d-flex align-items-center">
                <div className="circle-red"></div> CLOSED
              </div>}
            </div>
          </div>
          {truePhraseB && !lost && <Confetti />}
          <div className="d-flex flex-column pl-96 canvas-container">
            <div className={`you-won-title ${!truePhraseB && "d-none"} ${lost && "d-none"}`}>
              YOU WON!
            </div>
            {!truePhraseB &&
              <>
                <div className={`you-lost-title text-center  ${!lost && "d-none"}`}>
                  YOU LOST!<br /><span className='lost-emoji'> :( </span>
                </div>

                <div className={`font-minecraft-p mt-53 ${lost && "d-none"}`}>
                  {activeKeys.length}/12 {truePhraseB && "CORRECT"} words
                </div>
                <div className={`font-minecraft-p mt-53 ${!lost && "d-none"} text-center`}>
                  Try again next time, FORTUNE favors the BOLD
                </div>
              </>
            }
            <div className={`font-minecraft-p mt-53 ${lost && "d-none"}`}>
              {activeKeys.length > 0
                ? activeKeys.map((val, index) => (
                  
                  <span key={index}
                    className={`yellow-color ${checkPhrase &&
                      (indexPhrase?.includes(val)
                        ? "orange-phrase"
                        : "red-phrase")
                      } ${checkPhrase &&
                        indexPhrase[index] === 1 &&
                      "green-phrase"
                      } `}
                  >
                    {val + " "}
                  </span>
                ))
                : "Let’s do this (Other players are on it!)"}
            </div>
            <div
              className={`border-phrase font-minecraft-p ${truePhraseB && "d-none"
                }`}
            >
              {recentGuess ? (
                <div>
                  <div className="font-minecraft-p mt-53 z">RECENT GUESS </div>
                  {recentGuess.map((val, index) => (
                    <span key={index}
                      className={`yellow-color ${indexPhrase?.includes(val)
                        ? "orange-phrase"
                        : "red-phrase"
                        } ${indexPhrase[index] === 1 && "green-phrase"} `}
                    >
                      {val + " "}
                    </span>
                  ))}
                </div>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="circle-green"></div>
                      </td>
                      <td>Correct word, Correct position</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="circle-orange"></div>
                      </td>
                      <td>Correct word, Incorrect position</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="circle-red"></div>
                      </td>
                      <td>InCorrect word</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div
            onClick={checkHandler}
            className={`check-button ${activeKeys.length === 12 && "active-button-check"
              } 
          ${truePhraseB && !lost && "phrase-found-success"} ${wrongPhrase && "phrase-wrong-t"}`}
          >
            {!truePhraseB &&
              (!wrongPhrase
                ? "Check passphrase"
                : "Wrong passphrase, start again")}
            {truePhraseB && !lost && "Collect winnings"}
            {lost && "Sign up for the next round"}
          </div>
        </div>
      </div>
    </>
  );
}

export default Passphrase;