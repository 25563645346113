import { useNavigate } from "react-router-dom";
import Account from './Account';

function AdminHeader() {
    let navigate = useNavigate();
    // useEffect(() => {

    //     const fetchUser = async () => {
    //         const { data } = await authService.me();
    //         if (data) {
    //             setUser(data)
    //         }
    //     }
    //     fetchUser()

    // }, [])

    // function handleSignOut(){
    //     localStorage.removeItem("token");
    //     navigate(`/admin/login`);
    // }

    function handleNavAdminHome() {
        navigate(`/admin/home`);
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
                <div className="navbar-logo" onClick={handleNavAdminHome}>
                    PASS<span className="yellow-text">PHRASE</span>
                </div>
                <Account/>
                {/* <div className="navbar-user">
                    <i className="fas fa-user-circle user-avatar mr-5"></i>
                    <span className="mr-5 pointer" onClick={handleSignOut}>{user?.name}</span>
                    <span className="mr-5">|</span>
                    <span className="pointer" onClick={handleNavAdminHome}>SIGN OUT</span>
                </div> */}
            </div>
        </div>
    )
}

export default AdminHeader
