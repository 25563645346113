// import { useEffect, useState } from "react";
// import Card from "../components/card";
// import Card2 from "../components/card2";
import Footer from "../components/Footer";
import Header from "../components/Header";
// import gameService from "../services/gameService";
// import {Link} from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function WhitePaper(props) {
  const { balance, setAccount, setBalance, account } = props; 

  return (
    <>
    <Header
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      WIHTEPAPER PAGE
      <Footer />
    </>
  );
}

export default WhitePaper;
