import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import Account from "../../components/Account";
import gameService from '../../services/gameService';
import wordService from '../../services/wordService';
import "../../style/admin/new-game.css";
import {connectWallet, getChainId, isValidChain} from '../../utilities/Web3/account';
import {createContract} from '../../utilities/Web3/contract';
import { Regx } from '../../utilities/Constants/Constant';
import playerService from "../../services/playerService";

const numbers = [
    [12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    [22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    [32, 33, 34, 35, 36, 37, 38, 39, 40, 41],
    [42, 43, 44, 45, 46, 47, 48, 49, 50],
];

const prices = [0.1, 1, 10, 100, 1000];

function shuffle(array) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export default function NewGame() {
    const [activeNumber, setActiveNum] = useState('');
    const [price, setPrice] = useState("");
    const [activeSection, setActive] = useState(1);
    const [activeUser, setActiveUser] = useState(0);
    const [phraseText, setPhraseText] = useState('');
    const [phraseActive, setphraseActive] = useState([]);
    const [finishActive, setFinishActive] = useState(false);
    const imageRefs = useRef([]);
    const [loading, setLoading] = useState(false);
    const [words, setWords] = useState([]);
    const [noOfPlayers, setNoOfNumbers] = useState(numbers);
    // const [downloading, setDownloading] = useState(false);
    useEffect(() => {
        setFinishActive(phraseActive.length === 12);
    }, [phraseActive]);

    const [players, setPlayers] = useState([
        // { username: "Fortitude", clue: "Count942!" },
    ]);

    let navigate = useNavigate();

    const [adminPhrase, setAdminPhrase] = useState([])

    const fetchWords = async () => {
        const {data} = await wordService.list(activeNumber > 50 ? activeNumber : 50);
        setWords(data);
        setAdminPhrase(data.slice(0, 50));
    }

    const generatePlayer = async () => {
            let payload = {
                numberPlayer:activeNumber,
                passPhrase:phraseActive,
            }
            const {data} = await playerService.generatorplayer(payload)
            setPlayers(data.result)
    }

    const handdleCreateGame = async () => {
        const valid = await connect();
        if (!valid) return;

        const body = {
            numberPlayer: activeNumber,
            price,
            passPhraseGame: phraseActive,
            randomWord: shuffle(adminPhrase),
            words:words,
        };
        let gameId;

        if (!(await isValidChain())) {
            toast.warn('Please connect to the correct network');
            return;
        }

        try {
            setLoading(true);
            const {data} = await gameService.create(body);
            gameId = data.result._id;
            const uri = `${window.location.origin}/uri/${gameId}/{id}.json`;
            const res = await createContract(price, phraseText.trim(), data.result._id, activeNumber, uri);
            await gameService.setTokenAddress(gameId, res);
            navigate("/admin/live-games");
        } catch (error) {
            console.log(error);
            await gameService.remove(gameId);
            toast.error('Error creating game, please try again');
        } finally {
            setLoading(false);
        }
    }

    const connect = async () => {
        try {
            await connectWallet();
        } catch (error) {
            toast.error('Cannot connect to wallet');
            return false;
        }
        const chainId = await getChainId();
        const validChainId = process.env.REACT_APP_CHAIN;

        if (chainId.toString() !== validChainId) {
            toast.warn('Please connect to the correct network');
            return false;
        }
        return true;
    }

    const handelInput = (e) => {
        const re = Regx.onlyFullNumber;
        if (e.target.value === '' || re.test(e.target.value)) {
            setActiveNum(e.target.value);
            if(e.target.value > 50 ){
                noOfPlayers[3][10] = parseInt(e.target.value);
                setNoOfNumbers(noOfPlayers);
            }
        }
    }

    const handelPriceInput = (e) => {
        const re = Regx.onlyNumber;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPrice(e.target.value);
        }
    }
    if (loading) {
        return <div className="mt-131 sold-out-text wait-text">Game creation may take a few minutes.
        <br /> Please wait<div className="stage"><div className="dot-pulse"></div></div></div>
    } else
        return (
            <>
                <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
                    <div className="d-flex">
                        <img
                            onClick={() => {
                                if (activeSection === 1) {
                                    navigate("/admin/home");
                                } else {
                                    setActive(activeSection - 1);
                                }
                            }}
                            alt="back"
                            className="admin-new-g me-3 cursor-pointer"
                            src="/arrow.png"
                        />
                        <div className="navbar-logo" onClick={() => {
                            navigate("/admin/home");
                        }}>
                            PASS<span className="yellow-text">PHRASE</span>
                        </div>
                    </div>
                    <Account/>
                    {/* <div className="navbar-user">
                    <span>DALLAS</span>
                    <i className="fas fa-user-circle user-avatar"></i>
                    </div> */}
                </div>

                <div className="d-flex line-container w-100 justify-content-center">
                    <div
                        className={`status-line me-3 ${activeSection === 1 && "line-active"}`}
                    ></div>
                    <div
                        className={`status-line me-3 ${activeSection === 2 && "line-active"}`}
                    ></div>
                    <div
                        className={`status-line me-3 ${activeSection === 3 && "line-active"}`}
                    ></div>
                    <div
                        className={`status-line me-4 ${activeSection === 4 && "line-active"}`}
                    ></div>
                </div>

                {/* SECTION 1: Choose number players */}
                <div className={`${activeSection === 1 ? "section-1" : "d-none"}`}>
                    <div className="status-explain">Choose number of players</div>
                    <input
                        className="input-number"
                        onChange={handelInput}
                        value={activeNumber}
                        pattern="[0-9]+"
                        type="text"
                        placeholder="Number"
                    />
                    <div className=" ms-3 d-flex">
                        {noOfPlayers.map((v, key) => (
                            <div className="col" key={key}>
                                {v.map((val) => (
                                    <div className="row" key={val}
                                    onClick={() => {
                                        setActiveNum(val);
                                    }}>
                                        <span
                                            className={`numbers-clickable font-minecraft ${val === parseInt(activeNumber) && "active-row"
                                            }`}
                                        >
                                            {val}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end mt-3 mb-5">
                        <div
                            onClick={async () => {
                                await fetchWords();
                                setActive(2);
                            }}
                            className={`continue-button ${activeNumber >= 12 && activeNumber <= 100000 ? "active-button" : "disabled"}`}>
                            Continue
                        </div>
                    </div>
                </div>

                {/* SECTION 2: set price */}
                <div className={`${activeSection === 2 ? "section-1" : "d-none"}`}>
                    <div className="status-explain">Set price per NFT</div>
                    <input
                        className="input-number"
                        onChange={handelPriceInput}
                        value={price}
                        placeholder="Number"
                    />
                    <div className="d-flex">
                        <div className="col-md-1">
                            {prices.map((val) => (
                                <div className="row" key={val}
                                    onClick={() => {
                                        setPrice(val);
                                    }}>
                                    <div
                                        className={`numbers-clickable font-minecraft ${val === price && "active-row"} d-flex justify-content-between`}>
                                        <span>{val}</span>
                                        <span>ETH</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3 mb-5">
                        <div
                            onClick={() => {
                                setActive(3);

                            }}
                            className={`continue-button ${!!price ? "active-button" : "disabled"}`}
                        >
                            Continue
                        </div>
                    </div>
                </div>

                {/* SECTION 3: select phrase */}
                <div className={`${activeSection === 3 ? "section-3" : "d-none"}`}>
                    <div className="status-explain">BUILD your passphrase</div>
                    {/* <textarea
                        readOnly={true}
                        className="admin-phrase-text-area"
                        value={phraseText}
                    ></textarea> */}
                    <div className="admin-phrase-text-area">
                        {phraseText}
                    </div>
                    <div className="admin-phrase-list">
                        {adminPhrase.map((v) => (
                            <div key={v}
                                // onClick={() => {
                                //   setPhraseText(phraseText + " " + v);
                                //   setphraseActive([...phraseActive, v]);
                                // }}

                                onClick={() => {
                                    if (phraseText.includes(v)) {
                                        let text = phraseText.replace(v, '').trim();
                                        text = text.replace('  ', ' ').trim();
                                        setPhraseText(text);
                                        // setPhraseText(phraseText.replace(' ','').trim());
                                        const index = phraseActive.findIndex(t => t === v);
                                        const arr = [...phraseActive];
                                        arr.splice(index, 1);
                                        setphraseActive([...arr])
                                    } else {
                                        if (!phraseText.includes(v) && phraseActive.length < 12) {
                                            setPhraseText(phraseText + " " + v);
                                            setphraseActive([...phraseActive, v]);
                                        }
                                    }


                                }}
                                className={`admin-phrase-box ${phraseActive.includes(v) && "box-active"}`}
                            >






                                {v}
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-5 footer-text-phrase">
                        <div className="admin-phrase-info">
                            The completed passphrase will be locked and sent in an email to you.
                        </div>
                        {/* <div
                                onClick={handdleCreateGame}
                                className={`finish-button ${finishActive && "finish-button-active"
                                }`}
                            >
                                Finish settings and start game
                            </div> */}

                        <div
                            onClick={() => {
                                if(phraseActive.length === 12) {
                                    generatePlayer();
                                    setActive(4);
                                }
                            }}
                            // className="continue-button active-button"
                            className={`continue-button ${finishActive && "finish-button-active"
                            } `}
                        >
                            Continue
                        </div>

                    </div>
                </div>

                {/* SECTION 4: preview player */}
                <div className={`${activeSection === 4 ? "section-2" : "d-none"}`}>
                    <div className="status-explain">Mint NFTS for all the players</div>
                    <div className="section-2-container d-flex w-100">
                        <div style={{position: 'absolute', left: '-99999px'}}>
                            {players.map((player, key) => (
                                <div
                                    className="black-section-2 d-flex w-200 align-items-center justify-content-center word_image"
                                    key={key}
                                    ref={el => imageRefs.current[key] = el}>
                                    {player?.username}
                                </div>
                            ))}
                        </div>

                        <div className="black-section-2 d-flex w-50 align-items-center justify-content-center">
                            {players[activeUser]?.username}
                        </div>
                        <div className="list-main">
                            <div className="arrows">
                                <span
                                    onClick={() => {
                                        setActiveUser(activeUser > 0 ? activeUser - 1 : players.length - 1);
                                    }}
                                >
                                    <i className="fas fa-arrow-left me-2 cursor-pointer" />
                                </span>
                                <span
                                    onClick={() => {
                                        setActiveUser(activeUser < players.length - 1 ? activeUser + 1 : 0);
                                    }}
                                >
                                    <i className="fas fa-arrow-right cursor-pointer" />
                                </span>
                            </div>
                            <table className="">
                                <tbody>
                                    <tr className="table-row">
                                        <td className="table-font">Number</td>
                                        <td className="table-font-minecraft">
                                            Player {activeUser + 1}
                                        </td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-font">Word</td>
                                        <td className="table-font-minecraft">
                                            {players[activeUser]?.username}
                                        </td>
                                    </tr>
                                    {/* <tr className="table-row">
                                        <td className="table-font">Clue</td>
                                        <td className="table-font-minecraft">
                                            {players[activeUser]?.clue}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div className="page-buttons ">
                                {/* <div
                                        onClick={() => {
                                        setActive(4);
                                        }}
                                        className="continue-button active-button"
                                    >
                                        Continue
                                    </div> */}

                                <div
                                    onClick={handdleCreateGame}
                                    className={`finish-button ${finishActive && "finish-button-active"
                                    }`}
                                >
                                    Finish settings and start game
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
}
