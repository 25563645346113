import React from "react";
import { Link } from "react-router-dom";

const NftCollectionCard = ({ info, game , walletaddress}) => {
  return (
    <>
      <div className="card-wrapper">
        <Link 
        to={`/app/detail-page/${game._id}/${info.tokenId}`}>
          <div className="card-item">
            <div className="card-images">
              <h2
                className={
                  info.isBought  ? "red-text" : "yellow-text"
                }
              >
                {info.word}
              </h2>
            </div>
            <div className="card-footer">
              <div className="card-footer-row">
                <div className="card-footer-tittel">{info.word}</div>
                {info.isBought && !walletaddress && (
                  <div className="red-text">SOLD OUT</div>
                )}
              </div>
              <div className="card-footer-row">
                <div className="card-footer-tittel">Price</div>

                <div className="price yellow-text">
                  <i className="fab fa-ethereum"></i> {game?.price}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default NftCollectionCard;
