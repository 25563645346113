import { useEffect, useState } from "react";
import authService from '../services/authService';
import { useNavigate } from "react-router-dom";

function AdminHeader() {
    const [user, setUser] = useState(null);
    let navigate = useNavigate();
    useEffect(() => {

        const fetchUser = async () => {
            const { data } = await authService.me();
            if (data) {
                setUser(data)
            }
        }
        fetchUser()

    }, [])

    function handleSignOut() {
        localStorage.removeItem("token");
        navigate(`/admin/login`);
    }

    function handleNavAdminHome() {
        navigate(`/admin/home`);
    }
    return (
        <>
            <div className="navbar-user">
                <span className="mr-5 pointer" onClick={handleNavAdminHome}>{user?.name}</span>
                <span className="mr-5">&nbsp;|&nbsp;</span>
                <span className="pointer" onClick={handleSignOut}>SIGN OUT</span>
            </div>
        </>
    )
}

export default AdminHeader
