import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NftCollectionCard from "../components/nftCollectionCard";
import "../style/nft-collection.css";
import gameService from '../services/gameService';
// import backArrow from "../assets/icons/backarrow.svg";
import Tab from '../components/Tab';
import playerService from "../services/playerService";

const NftCollectionPlayer = (props) => {
  const { balance, setAccount, setBalance, account } = props;
  const params = useParams();
  const [nftsFiltered, setNftsFiltered] = useState([]);

  const [nfts, setNfts] = useState([]);
  const [dataLimit, setDataLimit] = useState(16);
  const [game, setGame] = useState();
  const [loading, setLoading] = useState(true);
  // const [filter, setFilter] = useState(0);
  // const filterText = ['View all', 'Sold', 'UnSold'];
  // const [isOwner, setIsOwner] = useState(false);
  let navigate = useNavigate();

  async function fetchGame() {
    const { data } = await gameService.view(params.id);
    setGame(data?.result);
    const nftList = await playerService.GetByGame(account);
    setNfts(nftList.data?.result);
    if(nftList.data?.result.length <= 0 && !account){
      navigate('/');
    }   
    setNftsFiltered(nftList);
    setLoading(false);
 
  }

  // console.log('param', params)

  // async function getSoldIds() {
  //   let ids = [];
  //   for (let i =0;i < nfts.length;i++) {
  //     if(nfts[i].walletAddress === account)
  //     {
  //       ids.push(nfts[i])
  //     }
  //   }
  //   setNftsFiltered(ids)
  //   setSoldIds(ids);
  //   if(ids.length <= 0 && !account){
  //     navigate('/');
  //   }
  //   setTimeout( setLoading(false),60000);
  //   // console.log('ids',ids)
  // }

  useEffect(() => {
    fetchGame();
   
  }, [params.id]);





 
  // const tabs = <Nav className='d-flex'>
  //   <div className='d-flex align-items-center justify-content-center game-opt active mx-3 my-1'>
  //     <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M13.6663 11.5002V0.833496H2.99967V11.5002H13.6663ZM6.33301 7.50016L7.68634 9.30683L9.66634 6.8335L12.333 10.1668H4.33301L6.33301 7.50016ZM0.333008 3.50016V14.1668H10.9997V12.8335H1.66634V3.50016H0.333008Z" fill="#BA9C00" />
  //     </svg>


  //     <div className="ms-2">
  //       <span >Collections</span>
  //     </div>
  //   </div>
  //   <div className='d-flex align-items-center justify-content-center game-opt my-1 cursor-pointer' onClick={() => navigate(`/app/nft-collection-detail-player/${params.id}`)}>
  //     <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1.33366 6.83317H14.667V8.1665H1.33366V6.83317ZM2.10033 4.13317L2.66699 3.1465L3.23366 4.13317L4.10033 3.63317L3.53366 2.6465H4.66699V1.6465H3.53366L4.10033 0.666504L3.23366 0.166504L2.66699 1.1465L2.10033 0.166504L1.23366 0.666504L1.80033 1.6465H0.666992V2.6465H1.80033L1.23366 3.63317L2.10033 4.13317ZM6.56699 3.63317L7.43366 4.13317L8.00033 3.1465L8.56699 4.13317L9.43366 3.63317L8.86699 2.6465H10.0003V1.6465H8.86699L9.43366 0.666504L8.56699 0.166504L8.00033 1.1465L7.43366 0.166504L6.56699 0.666504L7.13366 1.6465H6.00033V2.6465H7.13366L6.56699 3.63317ZM15.3337 1.6465H14.2003L14.767 0.666504L13.9003 0.166504L13.3337 1.1465L12.767 0.166504L11.9003 0.666504L12.467 1.6465H11.3337V2.6465H12.467L11.9003 3.63317L12.767 4.13317L13.3337 3.1465L13.9003 4.13317L14.767 3.63317L14.2003 2.6465H15.3337V1.6465Z" fill="black" />
  //     </svg>
  //     <div className="ms-2">
  //       <span className="yellow-text ">Games</span>
  //     </div>
  //   </div>

  // </Nav>

  return (
    <>
      <div>
        <Header backArrow midComponents={
          <Tab gameId={params.id} loading={loading} />}
          balance={balance}
          account={account}
          setAccount={setAccount}
          setBalance={setBalance}
          gameIdAsProp={params.id}
        />
        {/* <div className="banner-section">
          <div className="banner-container">
            <h1>
              {Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000} ETH <span className="yellow-text">WINNING PRIZE</span>
            </h1>
          </div>
        </div>
        <div className="dropdown dropdown-row">
          {isOwner &&
            <Link
              className="navbar-login cursor-pointer hover"
              to={`/app/instructions/${game._id}`}
            >
              Play Game
            </Link>
          }
        </div> */}
        <div className="nft-card-wrapper">
          { loading ?  
         <div className="mt-131 sold-out-text wait-text">Loading
         <div className="stage"><div className="dot-pulse"></div></div></div> :  nfts.length > 0 ?  <div className="nft-card-row">
          {nfts?.slice(0, dataLimit).map((info, key) => (
            <NftCollectionCard info={info} key={key} game={game} walletaddress={account} />
          ))}
        </div> : <>
            <div className="mt-131 sold-out-text">No NFTs you purchased!</div><div className="button-row">
              <div
                className="navbar-login cursor-pointer mt-40 cursor hover"
                onClick={() => navigate('/app/nft-collection-mainpage')}
              >
                Buy NFT
              </div>
            </div>
          </>
        }
        </div>
  
        <div className="button-row">
          {nftsFiltered?.length > 16 && (
            <div
              className="navbar-login cursor-pointer"
              onClick={() => setDataLimit(20)}
            >
              View more
            </div>
          )}
        </div>

        <div className="row mt-192">
          <div className="col">
            <span className="footer-text">
              Pass<span className="yellow-text">Phrase</span>
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NftCollectionPlayer;
