import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import icon from '../assets/icons/emailpage.svg'
import { Link, useParams } from 'react-router-dom'
import ShareGame from './ShareGame'

const SubscribeEmail = (props) => {
  const { balance, setAccount, setBalance, account } = props
  const [sharing, openModal] = useState(false)
  const { gameId } = useParams()
  const handleShare = () => {
    openModal(!sharing)
  }
  return (
    <>
      {sharing && <ShareGame title={'Share'} onClose={handleShare} />}
      <Header
        backArrow={true}
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className='card-banner'>
        <div className='banner-icon'>
          <img src={icon} alt='' />
        </div>
      </div>
      <div className='from-wrapper'>
        <h2>
          <Link to={`/app/instructions/${gameId}`}>
            We have emailed you a link to confirm <br />
            your subscription
          </Link>
        </h2>
        <div className='main-form form-tab'>
          <div className='main_container'>
            <div className='ready__started-box'>
              <form className='main__form'>
                <div className='text-center'>
                  <button className='btn btn-get mb-3'>
                    <a href='https://mail.google.com/mail/u/0/' target='_blank'>
                      Open Gmail
                    </a>
                  </button>
                </div>
                <div className='text-center'>
                  <button className='btn btn-get mb-3'>
                    <a href='https://mail.yahoo.com/' target='_blank'>
                      Open Yahoo
                    </a>
                  </button>
                </div>
                <div className='text-center'>
                  <button className='btn btn-get mb-3'>
                    <a href='https://outlook.live.com/' target='_blank'>
                      Open Hotmail
                    </a>
                  </button>
                </div>
              </form>
              <div className='text-center'>
                <button className='btn btn-get' onClick={handleShare}>
                  <span>Tweet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-wrapper'>
        <Footer />
      </div>
    </>
  )
}

export default SubscribeEmail
