import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const BackArrow = () => {
    const navigate = useNavigate()
    const [fill, setSvgFill] = useState('#fff')

    return (
        <svg
            className="back-button"
            onMouseLeave={() => setSvgFill('#fff')}
            onMouseOver={() => setSvgFill('#FFD600')}
            onClick={() => navigate(-1)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill={fill} />
        </svg>
    )
}

export default BackArrow
