import axios from "axios";
import { jwtManager } from '../helper/jwtManager';

const register = data => {
  return axios.post("users", data);
};

const login = async (email, password) => {
  const response = await axios.post("auth", {
    email,
    password,
  });

  if(response.data.completed){
    jwtManager.set(response.data.token);
  }
  return response;
};

const me = async () => {
  return await axios.get("auth/user");
};

const updateProfile = async (payload) => {
  if (payload?.new_password) payload.password = payload.new_password;
  return await axios.post("/auth/profile", payload);
}

const forgot = async (email) => {
  return await axios.post("/auth/reset_request", {email});
}

export default {
  forgot,
  register,
  login,
  me,
  updateProfile,
};
