import React from 'react'
import { Modal } from 'react-bootstrap'

const PopupModal = ({ title, body, show, classes, onHide, ...rest }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      {...rest}
      size='lg'
      aria-labelledby='contained-game-url'
      centered
      className={'share-modal'}
      style={{
        backdropFilter: 'blur(3px)',
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-contained-game-url'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes}>{body}</Modal.Body>
      <Modal.Footer>
        <button className='btn btn-get w-25' onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

PopupModal.defaultProps = {
  title: 'Confirmation',
  classes: '',
  body: null,
  show: true,
  onHide: () => {},
}

export default PopupModal
