import { createRef, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import gameService from '../../services/gameService.js';
import playerService from "../../services/playerService";
import "../../style/admin/nft-details.css";

function AdminNFTDetail() {
  const { id, playerId} = useParams();
  const { state } = useLocation();
  let navigate = useNavigate();
  const imageRef = createRef();
  const [nft, setNft] = useState({});
  const [game, setGame] = useState();
  async function fetchGame() {
    const { data } = await gameService.view(id);
    setGame(data?.result);
    // console.log('game', data)
  }

  async function fetchNft() {
    const { data } = await playerService.getOne(playerId);
    setNft(data.result);
  }

  useEffect(() => {
    fetchGame();
  }, [id]);

  // console.log(nft, 'nft')
  useEffect(() => {
    if (!game) return;
    fetchNft();
  }, [playerId, game]);

  function showSold() {
    if(nft.isBought){
    return <>
      <tr className="table-nft-row">
        <td className="table-title-text">Username</td>
        <td className="table-value-text">{nft.word}</td>
      </tr>
      <tr className="table-nft-row">
        <td className="table-title-text">Email</td>
        <td className="table-value-text">{nft.email}</td>
      </tr>
      <tr className="table-nft-row">
        <td className="table-title-text">Wallet</td>
        <td className="table-value-text-small">{nft?.walletAddress}</td>
      </tr>
    </>
    }
  }

  // async function downloadNft() {
  //   printToFile(imageRef.current, nft.word);
  // }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          <img
            onClick={() => {
              navigate(-1);
            }}
            src="/arrow.png"
            className="me-3 cursor-pointer"
            alt=""
          />
          {nft?.word}
        </div>
        <div className="navbar-user">
          <span>DALLAS</span>
          <i className="fas fa-user-circle user-avatar"></i>
        </div>
      </div>

      <div className="nft-details-container d-flex flex-wrap">
        <div className="nft-title" ref={imageRef}>{nft?.word}</div>
        <div className="nft-details-text">
          <table>
            <tbody>
            <tr className="table-nft-row">
              <td className="table-title-text">Number</td>
              <td className="table-value-text">{state.total} - Player {nft?.tokenId}</td>
            </tr>
            <tr className="table-nft-row">
              <td className="table-title-text">Word</td>
              <td className="table-value-text">{nft?.word}</td>
            </tr>
       
            {showSold()}
            </tbody>
          </table>
          {/* <div className="download-nft-button nft-button-m" onClick={downloadNft}>Download NFT</div> */}
        </div>
      </div>
    </>
  );
}

export default AdminNFTDetail;
