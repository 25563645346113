import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Header from '../components/Header'
import '../style/purchase-successful.css'
import Footer from '../components/Footer'
import greenTick from '../assets/icons/green.png'
import { useNavigate } from 'react-router-dom'
import playerService from '../services/playerService'
import { useParams } from 'react-router'
// import gameService from '../services/gameService.js'
import ShareGame from './ShareGame'

const PurchaseSuccessful = (props) => {
  const { balance, setAccount, setBalance, account } = props
  const { id } = useParams()
  const navigate = useNavigate()

  const inititalValue = {
    username: '',
    email: '',
  }
  const [userInfo, setUserInfo] = useState(inititalValue)
  const [sharing, openModal] = useState(false)
  const { username, email } = userInfo
  const [isUserValid, setIsUserValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isUserNameValid, setIsUserNameValid] = useState(false)
  const [players, setPlayers] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'username') {
      const index = players.findIndex(
        (player) => player.userName === value.trim()
      )
      if (index !== -1) {
        setIsUserNameValid(true)
      } else {
        setIsUserNameValid(false)
      }
    }
    if (name === 'email') {
      const index = players.findIndex(
        (player) => value.trim().length > 0 && player.email === value.trim()
      )
      if (index !== -1) {
        setIsEmailValid(true)
      } else {
        setIsEmailValid(false)
      }
    }

    setUserInfo({ ...userInfo, [name]: value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isUserValid) {
      const location = window.location.href
      const path = location.split('/app')[0] + '/app/instructions/' + id.split('&')[0]
      const walletAddress = localStorage.getItem('account')
      const body = {
        word: id.split('&')[1],
        game: id.split('&')[0],
        username,
        email,
        walletAddress,
        path,
      
      }
      navigate(`/purchase/success-subscribe-email/${id.split('&')[0]}`)
      const { data } = await playerService.update(body)
      if (data.complete) {
        playerService.sendMail(body)
        // navigate(`/app/instructions/${id.split('&')[0]}`);
      
      } else {
        toast.error(data.result);
      }
    }
  }

  useEffect(() => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // if (value.match(mailformat)) {
    if (username.trim().length > 0 && email.match(mailformat)) {
      // }
      // if (isEmailValid === true && isUserNameValid === true) {
      setIsUserValid(true)
    } else {
      setIsUserValid(false)
    }
  }, [username, email])

  useEffect(() => {
    const fetchPlayers = async () => {
      const { data } = await playerService.list({ game: id.split('&')[0] })
      if (data.complete) {
        setPlayers(data.result)
      }
    }
    fetchPlayers()
  }, [id])

  const handleShare = () => {
    openModal(!sharing)
  }

  return (
    <>
      {sharing && <ShareGame title={'Share'} onClose={handleShare} />}
      <Header
        backArrow={true}
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className='card-banner'>
        <div className='detail-images'>
          <div className='detail-images-wpapper'>
            <h1 className='yellow-text'>{id.split('&')[1]}</h1>
          </div>
        </div>
      </div>
      <div className='from-wrapper'>
        <h2>
          Congratulations on owning this NFT and Participating in the game!
        </h2>
        <div className='main-form'>
          <p>
            Subscribe with us so we can notify you via email once the next game
            is about to start. We won’t spam you.
          </p>
          <div className='main_container'>
            <div className='ready__started-box'>
              <form className='main__form'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    name='username'
                    placeholder='Username'
                    value={username}
                    onChange={(e) => handleChange(e)}
                  />

                  <label htmlFor='Username'>Username</label>
                  {isUserNameValid && (
                    <img src={greenTick} className='green-tick' alt='' />
                  )}
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor='Email'>Email</label>
                  {isEmailValid && (
                    <img src={greenTick} className='green-tick' alt='' />
                  )}{' '}
                </div>
                <div className='text-center form-group'>
                  <button
                    disabled={!isUserValid}
                    className='btn btn-get'
                    onClick={handleSubmit}
                  >
                    <span> Subscribe & Continue</span>
                  </button>
                </div>
              </form>
              <div className='text-center'>
                <button className='btn btn-get' onClick={handleShare}>
                  <span>Tweet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PurchaseSuccessful
