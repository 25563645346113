import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import authService from '../../services/authService';
import "../../style/admin/login.css";

function AdminLogin() {
  let navigate = useNavigate();
  const [passwordActive, setPsActive] = useState(false);
  // const [buttonActive, setButtonActive] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await authService.login(email,password);
      if (!res.data.completed) {
        toast.error(res.data.message);
      } else {
        navigate("/admin/home");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center py-4 navbar-main">
        <div className="navbar-logo">
          PASS<span className="yellow-text">PHRASE</span>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center mt-144">
        <div className="register-box d-flex flex-column w-410 ">
          <span className="register-title">Welcome, Gate Keeper</span>
          <form onSubmit={loginHandler}>
            <input
              placeholder="Email"
              type="email"
              className="input-register"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="password-container">
              <div
                className="password-eye"
                onClick={() => {
                  setPsActive(!passwordActive);
                }}
              >
                {!passwordActive ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </div>
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
                type={!passwordActive ? "password" : "text"}
                className="input-register"
              />
            </div>
            <button type="submit" className={`register-button w-410`}>
              Login
            </button>
          </form>

          <Link to="/admin/forgot-password" className="forgot-password">
            Forgot password?
          </Link>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
