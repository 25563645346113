import React, { useEffect, useState } from "react";
import "../style/detail-page.css";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Header from "../components/Header";
import ConnectWalletModal from "../components/connectWalletModal";
import backArrow from "../assets/icons/backarrow.svg";
import gameService from '../services/gameService.js';
import { buyToken, countMinted , } from "../utilities/Web3/contract";
import { isValidChain } from "../utilities/Web3/account";
import { getEllipsisTxt } from "../helper/formatter";
import playerService from "../services/playerService";

import { Button} from "react-bootstrap";

const DetailPage = (props) => {
  const { balance, setAccount, setBalance, account } = props;
  const { gameId , tokenId } = useParams();
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [USD, setUSD] = useState();
  const [nft, setNft] = useState({});
  const [game, setGame] = useState();
  const [loading, setLoading] = useState(false);
 
  const [numberPlayer,setNumberPlayer] = useState(0)
  const navigate = useNavigate();
  const [numSold, setNumSold] = useState(0);
  const [extraLoading, setExtraLoading] = useState(false);
  const [extraLoadingTime, setExtraLoadingTime] = useState(0);

  const exchangeEthToUSD = async () => {
    const { data } = await gameService.exchangeEthToUSD();
    setUSD(data.USD);
  }
  // const getNftsSold = async () => {
  //   const { data } = await gameService.view(gameId);
  //   setNumSold(data?.result.sold);
  //   return data
  // }
  async function fetchGame() {
      exchangeEthToUSD()
      const { data } = await gameService.view(gameId);
     
      let numberPlayer = (data?.result.numberPlayer).toLocaleString('en-US');
      setGame(data?.result);
      setNumSold(data?.result.sold);
      setNumberPlayer(numberPlayer);
      if(tokenId) {
        const { data: player } = await playerService.getOne(tokenId,gameId);
        setNft(player?.result);
      }
     
     
      setInterval(exchangeEthToUSD, 60000);
    
  }

  



  useEffect(() => {
    fetchGame();
    // async function init() {
    //   const pTx  = await localStorage.getItem('pendingTx');
    //   if(pTx && pTx !== 'null') {
    //     const {id, tx, ...rest}  = JSON.parse(pTx); 
    //     const pendingTxGame = rest.game;
    //     if (pendingTxGame._id === gameId && id === nft?.tokenId) {
    //         Transaction.setTransaction(pendingTxGame, tx, id);
    //         checkTransactionStatus();
    //     } else {
    //       navigate(`/app/detail-page/${pendingTxGame._id}/${playerId}`);
    //     }
    //   }
    // };
    // init();

    // return () => componentMounted.current = false
  }, [gameId]);

  // async function checkTransactionStatus() {
  //   setExtraLoading(true);
  //   let timeout = 30;
  //   setExtraLoadingTime(timeout)
  //   const interval = setInterval(() => {
  //     if (timeout <= 0) {
  //       clearInterval(interval);
  //     }
  //     setExtraLoadingTime(prevState => prevState -1);
  //   }, 1000);
  //   setTimeout(async () => {
  //     try {
  //         const gameData = game;
  //         const numSoldData = numSold
  //         const nftData  = nfts;
  //         const tx = await getTransactionsDetails(Transaction.getTransaction());
  //         localStorage.removeItem('pendingTx');
  //         if (tx.status === true) {
  //           const location = window.location.href;
  //           const path = location.split('app')[0] + 'app/instructions/' + gameId;
  //           updateSoldNft(gameData, path, numSoldData, nftData.word, account);
  //           navigate(`/app/purchase-successful/${gameData._id}&${nftData.word}`);
  //         } else {
  //           toast.error('Transaction was failed.');
  //           setExtraLoading(false);
  //         }
  //     }
  //     catch(e) {
  //       console.log(e);
  //       // toast.error('Transaction is still not confirmed. Please wait.');
  //       // clearInterval(interval);
  //       // checkTransactionStatus();
  //     }
  //   }, )
  // }

  async function buyNFT() {
    setLoading(true);
    try {
      if (!(await isValidChain())) {
        toast.error('Please connect to the correct network');
        return;
      }
      const nftSolds = await countMinted(game.tokenAddress)
      console.log("nftSolds",nftSolds)
      setNumSold(nftSolds);
      if (numberPlayer === nftSolds.toLocaleString('en-US')) {
          toast.warn('Nft has been sold out !');
          return;
      } else {
        const location = window.location.href;
        const path = location.split('app')[0] + 'app/instructions/' + gameId;
        const result = await buyToken(game.tokenAddress,game, path ,account, game.sold + 1 );
        // const result = await buyToken(game.tokenAddress,game, path ,account, nftSolds );
        console.log("game buy", game);
        const payLoad = { sold: game.sold + 1, path }
        const soldId = result.events.tokenSold.returnValues.tokenId;
        // await gameService.update(gameId, payLoad)
        if (result.blockHash) {
          navigate(`/app/purchase-successful/${game._id}&${soldId}`);
        }
      }

    } catch (error) {
      toast.error(error.message);
      // if user reject metamask transaction
      if(error.code !== 4001) {
        // checkTransactionStatus();
      }
      
    } finally {
      setLoading(false);
    }
  }
  // useEffect(()=>{
  //   console.log("Account Value::::::", account)
  // },[account])

  function showClue() {
    if (nft?.isBought) {
      if (nft?.walletAddress?.toLowerCase() === account) {
        return <p>
          Clue: <span style={{ color: '#ffd600' }}>{nft?.clue}</span><br />
          You prepare to compete for a prize worth {Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000} ETH.
        </p>;
      } else {
        return <p>This NFT has owner. Please, purchase another NFT and prepare to compete for a prize worth {Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000} ETH.</p>
      }
    }
    else {
      return <p>
        Your instructions to participate in the next round of
        Passphrase will be revealed to you after the purchase of this
        NFT. Own it and prepare to compete for a prize worth {Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000} ETH.
      </p>;
    }
  }
  function showButtonPurchase() {
    if (account === nft?.owner?.toLowerCase()) {
      return <></>
    }
   
    if (!nft.isBought) {
      if (localStorage.getItem('account') && balance !== undefined) {
        return (
            <Button 
              className={`main-button cursor-pointer purchase-button `}
              onClick={buyNFT}
              disabled={numberPlayer === numSold.toLocaleString('en-US')}
            >
            {/* {loading ? 'Wait for process...' : 'Purchase'} */}
              Mint          
            </Button>
        )
      }
    else {
        return (
          <Button 
            className={`mint-button cursor-pointer`}
            onClick={() => setShowConnectWallet(true)}
          >
            Connect wallet & mint
          </Button>
        )
      }
    }
  }

  if (loading) {
    return <div className="mt-131 sold-out-text wait-text">Proccessing, Please wait.<div className="stage"><div className="dot-pulse"></div></div><br /> You'll be redirected shortly</div>
  } else if (extraLoading) {
    return <div className="mt-131 sold-out-text wait-text" style={{width: '100%'}}>We are checking your transaction agian, Please wait.
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      <br /> You'll be redirected in { extraLoadingTime } </div>
  } else
    return (
      <>
        <Header
          balance={balance}
          account={account}
          setAccount={setAccount}
          setBalance={setBalance}
          backArrow={backArrow}
        />
        <div className="detail-wrapper">
          <div className="detail-container">
            <div className="text-title">
              <span class="text-guess">GUESS THE PASSPHRASE & WIN</span>
              <br/>
              <span class="text-money">$ {new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(Math.round(USD * (Math.round((game?.price * game?.numberPlayer * 0.8) * 10000) / 10000) * 1000) / 1000)}</span>
            </div>
            
            <div className="detail-row">
              <div className="detail-images">
                <div className="detail-images-wpapper">
                  <h1 className={"black-text"}>{tokenId ? tokenId.toLocaleString('en-US') : '?' }</h1>
                </div>
              </div>
              <div className="detail-card-text">
              <div className="text-mint">
                  <div className="detail-hd">NFTs minted</div>
                  <div className="text-mint">{numSold} / {numberPlayer}</div>
                </div>
                <div className="price-text">
                  <div className="detail-hd">Price</div>
                  <div><i className="fab fa-ethereum"></i> {game?.price}</div>
                </div>
                <div className="owner-text">
                  <div className="detail-hd">Owner</div>
                  <h3>{ tokenId ? getEllipsisTxt(nft[0]?.walletAddress) : "PASSPHRASE"}</h3>
                </div>
                <div className="owner-text">
                  <div className="detail-hd">Story</div>
                  {showClue()}
                </div>
                <br />
                {tokenId ||  numberPlayer === numSold.toLocaleString('en-US')  ? null : showButtonPurchase() }
              </div>
            </div>
          </div>
        </div>
        {showConnectWallet && (
          <ConnectWalletModal
            show={showConnectWallet}
            onHide={() => setShowConnectWallet(false)}
            actions = {{
              setAccount,
              setBalance
            }}
          />
        )}
        <Footer />
      </>
    );
};

export default DetailPage;
