import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackArrow from "./BackArrow";
import ConnectWalletModal from "./connectWalletModal";
import Web3 from "web3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import userIcon from "../assets/icons/userIcon.svg";
import playerService from '../services/playerService.js'

const Header = (props) => {
  const url = process.env.RPC_URL;
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545", url);
  const navigate = useNavigate();
  const { balance, setAccount, setBalance, backArrow, midComponents, account, user1 } = props;
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [user, setUser] = useState('');
  const [latestGameId, setLatestGameId] = useState('');
  const location = useLocation();
  const [reload, setReLoad] = useState(false);
  useEffect(() => {
    const checkNetwork = async () => {
      const validChainId = process.env.REACT_APP_CHAIN;
      try {
        await window.ethereum?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x' + validChainId }] });
      }
      catch (err) {
        
        toast.error('First connect to wallet.', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    checkNetwork();
  
  }, [])
function metaMaskDetector(){
  window.ethereum?.on('accountsChanged', (accounts) => {
    if (!accounts.length) {
      // logic to handle what happens once MetaMask is locked
      setBalance(undefined);
      localStorage.removeItem('account');
      setReLoad(!reload)
      
    }
});
}

useEffect(()=>{

  metaMaskDetector()
},[reload])

  useEffect(() => {
    const getBalance = async () => {
      if (window.ethereum !== undefined) {
       try {
        const address = await window.ethereum?.enable();
        // setAccount(await address[0]);
        if (address.length > 0) {
          const balance = await web3.eth.getBalance(address[0]);
          const ethBalance = web3.utils.fromWei(balance, "ether");
          setBalance(ethBalance);
        }
       } catch (error) {
        // toast.error('First connect to wallet.', {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
       }
      }
    }
    getBalance();

  }, [])

  async function fetchPlayer() {
    try {
        const { data } = await playerService.listPlayerAccount(account);
        if (data) {
            if(data.PlayerByWallet.length > 0){
                setLatestGameId(data.PlayerByWallet[data.PlayerByWallet.length-1].game)
                setUsernameIfNotEmpty(data.PlayerByWallet);
            }
        } else {
          
        }

    } catch (error) {
        // console.log(error)
    }
  }
  
  async function setUsernameIfNotEmpty(result) {
    for( let i = result.length - 1; i >= 0; i--) {
      if(result[i].userName !== "") {
        setUser(result[i].userName)
        break;
      }
    }
    return true;
  }

  useEffect(() => {
    if (showUserName()) {
      fetchPlayer();
    }
  }, [account,location, user])

  function showUserName() {
    if (
      location.pathname.includes('/') ||
      location.pathname.includes('/app/nft-collection-mainpage') || 
      location.pathname.includes('/app/detail-page') || 
      location.pathname.includes('/app/purchase-successful') ||
      location.pathname.includes('/app/nft-collection-detail-player') ||
      location.pathname.includes('app/game') ||
      location.pathname.includes('/app/instructions') ||
      location.pathname.includes('/app/success-subscribe-email')
    ) {
      return true;
    }
    return false;
  }

  function displayAccount() {
    if (location.pathname.includes('instructions') || location.pathname.includes('nft-collection-detail-player') || location.pathname.includes('app/game')) {
      return <div className="account">
        <span className="hover" onClick={() => navigate(`/app/instructions/${latestGameId}`)}>
          {user ? user: user1} | {" "}
        </span>
        <span>{balance? Math.round(balance * 10000) / 10000: null} ETH | </span>
        <span>
          <img src={userIcon} alt="" />
        </span>

      </div>
    } else {

      if (localStorage.getItem('account') && balance !== undefined) {
        return <div>
          {/* <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic"> */}

          <span className="pointer" onClick={() => navigate(`/app/instructions/${latestGameId}`)}>
            {user? user: user1}
          </span>
          <span>{user || user1? " | ": ""}</span>

          <span>{balance? Math.round(balance * 10000) / 10000: null} ETH | </span>

          <span className="pointer" onClick={() => {
            setBalance(undefined);
            localStorage.removeItem('account');
          }
          }>
            SIGN OUT
          </span>

          {/* </Dropdown.Toggle>
      </Dropdown> */}
        </div>
      } else {
        return !localStorage.getItem('account')? <div
          className="navbar-login cursor-pointer"
          onClick={() => setShowConnectWallet(true)}
        >
          Connect Wallet
        </div>: null
      }
    }

  }

  return (
    <div>
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          {backArrow && (
            <BackArrow />
          )}
          <span onClick={() => navigate("/")}>PASS
            <span className="yellow-text" >
              PHRASE
            </span>
          </span>
        </div>
        {midComponents && midComponents}
        {displayAccount()}
      </div>
      <div>
        {showConnectWallet && (
          <ConnectWalletModal
            actions = {{
              setAccount,
              setBalance
            }}
            //  setAccount={setAccount}
            // setBalance={setBalance}
            show={showConnectWallet}
            onHide={() => setShowConnectWallet(false)}
            
          />
        )}
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return false;
}

export default React.memo(Header, areEqual);
