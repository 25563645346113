import "./style/main.css";
import "./style/responsive.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/home";
import Register from "./pages/register";
import RegisterDone from "./pages/register-done";
import AdminLogin from "./pages/admin/login";
import AdminForgotPassword from "./pages/admin/forgot-password";
import AdminHome from "./pages/admin/home";
import NewGame from "./pages/admin/new-game";
import AdminLiveGames from "./pages/admin/live-games";
import AdminGameDetail from "./pages/admin/game-detail";
import AdminNFTDetail from "./pages/admin/nft-detail";
import Passphrase from "./pages/app/passphrase";
import NftCollection from "./pages/nft-collection";
import DetailPage from "./pages/detail-page";
import { useEffect, useState } from "react";
import Web3 from "web3";
import NftCollectionMainpage from "./pages/nft-collection-mainpage";
import Instructions from "./pages/app/instructions";
import PurchaseSuccessful from "./pages/purchase-successful";
import SubscribeEmail from "./pages/SubscribeEmail";
import authService from './services/authService';
import Term from "./pages/term";
import Privacy from "./pages/privacy";
import WhitePaper from "./pages/whitePaper";
import NftCollectionPlayer from "./pages/nft-collection-player";
import PlayerGame from "./pages/player-games";
import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const getAccount = () => {
  const account = localStorage.getItem("account");
  if (account) {
    return localStorage.getItem("account");
  } else {
    return false;
  }
};

ReactGA.initialize(process.env.REACT_APP_GA);

function App() {
  const web3 = new Web3(
    Web3.givenProvider ||
    "http://localhost:8545" ||
    process.env.RPC_URL
  );

  const [account, setAccount] = useState(getAccount());
  const [balance, setBalance] = useState();
  const [login, setLogin] = useState(false);
  const location = useLocation();

  window.ethereum?.on('accountsChanged', async function (accounts) {
    localStorage.setItem("account", accounts[0]);
    
    setAccount(accounts[0]);
    if (accounts !== null) {
      const balance = await web3.eth.getBalance(accounts[0]);
      const ethBalance = web3.utils.fromWei(balance, "ether");
      
      setBalance(ethBalance);
    }
  })

  // const connectWalletOnLoad = async () => {
  //   const balance = await web3.eth.getBalance(account);
  //   const ethBalance = web3.utils.fromWei(balance, "ether");
  //   setBalance(Number(ethBalance).toFixed(4));
  // };

  useEffect(() => {
    if (account) {
      localStorage.setItem("account",account)
    }
  }, [account]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await authService.me();
      if (data.complete === false) {
        setLogin(false)
      } else {
        setLogin(true)
      }
    }

    if (location.pathname.includes('admin')) {
      if(localStorage.getItem('token')){
        fetchUser()
      }else{
        setLogin(false)
      }
    }

    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [location])
  
  return (
    <div>
      <div className="container-main">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/register-done" element={<RegisterDone />} />
          {/* <Route path="/admin/login" element={<AdminLogin />} /> */}
          <Route path="/admin/login" element={login ? <Navigate replace to="/admin/home" /> : <AdminLogin />} />
          <Route
            path="/admin/forgot-password"
            element={<AdminForgotPassword />}
          />
          <Route path="/admin" element={login ? <AdminHome /> : <Navigate replace to="/admin/login" />} />
          <Route path="/admin/home" element={login ? <AdminHome /> : <Navigate replace to="/admin/login" />} />
          <Route path="/admin/new-game" element={login ? <NewGame /> : <Navigate replace to="/admin/login" />} />
          <Route path="/admin/live-games" element={login ? <AdminLiveGames /> : <Navigate replace to="/admin/login" />} />
          <Route
            path="/admin/live-games/:id"
            // element={<AdminGameDetail /> }
            element={login ? <AdminGameDetail /> : <Navigate replace to="/admin/login" />}
          />
          <Route
            path="/admin/live-games/:id/:playerId"
            // element={<AdminNFTDetail />}
            element={login ? <AdminNFTDetail /> : <Navigate replace to="/admin/login" />}
          />
          <Route path="/app/passphrase/:gameId" element={<Passphrase account={account}/>} />
          <Route
            path="/app/nft-collection-mainpage"
            element={
              <NftCollectionMainpage
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/nft-collection-detail/:id"
            element={
              <NftCollection
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/nft-collection-detail-player/:id"
            element={
              <NftCollectionPlayer
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/game"
            element={
              <PlayerGame
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/detail-page/:gameId"
            element={
              <DetailPage
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/detail-page/:gameId/:tokenId"
            element={
              <DetailPage
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/purchase-successful/:id"
            element={
              <PurchaseSuccessful
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/purchase/success-subscribe-email/:gameId"
            element={
              <SubscribeEmail
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/app/instructions/:gameId"
            element={
              <Instructions
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
              />
            }
          />
          <Route
            path="/info/terms"
            element={
              <Term
              balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}/>
            }
          />
          <Route
            path="/info/privacy"
            element={
              <Privacy
              balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}/>
            }
          />
          <Route
            path="/info/whitepaper"
            element={
              <WhitePaper
              balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}/>
            }
          />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
