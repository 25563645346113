function Card2(props) {
  return (
    <>
      <div className="card-2-m">
        <div className="card2-text">{props.text}</div>
        <div className="card main-card card2-settings">
          <div className="card-text-showcase"></div>
          <div className="card-text-content">
            <table className="w-100">
              <tbody>
                <tr className="w-100">
                  <td className="float-start">PRICE</td>
                  <td className="float-end">
                    <span className="yellow-text">
                      <i className="fab fa-ethereum"></i> {props.price}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card2;
