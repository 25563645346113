import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader";
import "../../style/admin/home.css";

function AdminHome() {
  let navigate = useNavigate();
  // const [user, setUser] = useState(null);
  const startGameHandler = () => {
    navigate("/admin/new-game");
  };

  //  useEffect(() => {

  //   const fetchUser = async () => {
  //     const { data } = await authService.me();
  //     if (data) {
  //       setUser(data)
  //   }
  // }
  //   fetchUser()
  
  // }, [])

  return (
    <>
    <AdminHeader/>
      {/* <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          PASS<span className="yellow-text">PHRASE</span>
        </div>
        <div className="navbar-user">
          <i className="fas fa-user-circle user-avatar mr-5"></i>
          <span>{user?.name}</span>
        </div>
      </div> */}
      <div className="admin-welcome-text">
        Let’s make
        <br /> things
        <br /> interesting.
      </div>
      <button onClick={startGameHandler} className="admin-home-button me-3">
        Start a New Game
      </button>
      <button onClick={() => navigate("/admin/live-games")} className="admin-home-button">
        Manage Games
      </button>
    </>
  );
}

export default AdminHome;
