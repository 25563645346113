import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../style/nft-collection-mainpage.css";


const NftCollectionMainpage = (props) => {
  const { balance, setAccount, setBalance, account } = props;

  return (
    <>
      <Header
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className="banner-section1">
        <div className="banner-container">
          <h1>
            PASSPHRASE <span className="yellow-text">TERMS</span>
          </h1>
          <p>
            You Own Your NFT: By connecting your Ethereum wallet and purchasing a NFT with our smart contract, you gain full and complete ownership of your NFT. <br />
            Limitations of Liability for Gas, Failed Transactions, and other Bugs. We have worked very hard to make sure that there are no bugs in our smart contract and that our initial NFT launch is smooth as possible. As we have seen in many previous projects, however, sometimes things break. <br />
            You agree to not hold NFTs accountable for any losses you may incur as a consequence of minting your NFT. These potential losses include any gas fees for failed transactions, and any excessive gas fees during the purchasing process.<br />
            NFTs Are Not Intended as Investments: NFTs serve exclusively as Non-Fungible Tokens for you to play in the nominated game collection. They are not intended for any investment purposes. We make absolutely no promise or guarantee that these NFTs will hold any particular value once purchased on our website.<br />
            You are responsible for any tax liability which may arise from minting or reselling your NFT(s). Class Action Waiver: You agree to waive any class action status, and any legal dispute around the NFTs project. Children: You agree that you are over the age of 18, or the legal age within your jurisdiction.<br />

            The purchaser may not cancel (e.g. return, refund) this agreement after it has been concluded.

          </p>
        </div>
      </div>

      <div className="row mt-10">
        <div className="col">
          <span className="footer-text">
            Pass<span className="yellow-text">Phrase</span>
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};



export default NftCollectionMainpage;

